@import '../../../styles/base-refs';

.worldExploration {
  position: relative;

  h3 {
    @include header;
  }

  p {
    margin-bottom: 24px;
    @include bodytext;
  }

  .copyHeader {
    margin-bottom: 36px;
    padding: 0 24px;
  }

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    mix-blend-mode: multiply;
    opacity: 0.69;
  }

  .learnButton {
    max-width: 240px;
  }

  .copy {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    text-align: center;

    >img {
      max-width: 546px;

      @include mobile {
        max-width: 100%;
      }
    }
  }

  .serendaleSection,
  .crystalvaleSection,
  .sunderedIslesSection {
    position: relative;
    display: flex;
    align-items: center;
    min-height: 700px;
    padding: 0 32px;

    @include mobile {
      min-height: 500px;
    }

    p {
      max-width: 500px;
      text-shadow: 0px 3px 6px #000000aa;
    }
  }

  .serendaleSection {
    justify-content: flex-start;
    background: transparent url('../img/worldexploration/serendale-map.png') center center / cover no-repeat padding-box;
    box-shadow: 0px 3px 30px #00000096;

    @include tablet {
      justify-content: center;
    }

    .overlay {
      background: transparent linear-gradient(90deg, #14391a 0%, #627a6600 100%) 0% 0% no-repeat padding-box;

      @include tablet {
        background: transparent linear-gradient(0deg, #14391a 0%, #627a6600 100%) 0% 0% no-repeat padding-box;
      }
    }
  }

  .crystalvaleSection {
    justify-content: flex-end;
    background: transparent url('../img/worldexploration/crystalvale-map.png') center center / cover no-repeat padding-box;
    box-shadow: 0px 3px 30px #00000096;

    @include tablet {
      justify-content: center;
    }

    .overlay {
      background: transparent linear-gradient(270deg, #142739 0%, #626e7a00 100%) 0% 0% no-repeat padding-box;

      @include mobile {
        background: transparent linear-gradient(0deg, #142739 0%, #626e7a00 100%) 0% 0% no-repeat padding-box;
      }
    }
  }

  .sunderedIslesSection {
    justify-content: flex-start;
    background: transparent url('../img/worldexploration/sundered-isles-map.png') center center / cover no-repeat padding-box;
    box-shadow: 0px 3px 30px #00000096;

    @include tablet {
      justify-content: center;
    }

    .overlay {
      background: transparent linear-gradient(90deg, #14391A 0%, #627A6600 100%) 0% 0% no-repeat padding-box;
      mix-blend-mode: multiply;
      opacity: 0.69;
    }
  }
}

$pixel-font: 'DodgeFont';
$serif-font: 'Lora', Georgia, serif;
$sans-serif-font: 'Poppins', Helvetica Neue, sans-serif;
$antique-font: 'RomanAntique', 'Lora', Georgia, serif;
$sans-serif-ui-font: 'LiberationSans', Helvetica Neue, sans-serif;

@mixin header {
  text-align: center;
  font: normal normal normal 34px/43px Lora;
  letter-spacing: 0px;
  color: #dedede;

  @include mobile {
    font-size: 24px;
    line-height: 28px;
  }
}

@mixin subheader {
  text-align: center;
  font: normal normal 500 20px/30px Poppins;
  letter-spacing: 1.6px;
  color: #dedede;
  text-transform: uppercase;
}

@mixin bodytext {
  text-align: center;
  font: normal normal normal 16px/25px Poppins;
  letter-spacing: 0px;
  color: #dedede;
}

@mixin learnmore {
  text-align: center;
  font: normal normal 500 20px/30px Poppins;
  letter-spacing: 1.6px;
  color: #91d565;
  text-transform: uppercase;
  opacity: 1;
  transition: 0.2s color ease-in-out;

  &:hover {
    color: #63f043;
  }
}

.languagePicker {
  position: absolute;
  top: 20px;
  left: 20px;
  z-index: 9;
  display: flex;
  gap: 12px;

  .language {
    cursor: pointer;
    filter: grayscale(1);
    font-size: 36px;

    &.active {
      filter: grayscale(0);
    }
  }
}

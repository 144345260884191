@import '../../../styles/base-refs';

.roadmap {
  padding: 64px;
  background: transparent url('../img/roadmap/roadmap-bg.png') 0% 0% no-repeat padding-box;
  margin: 0 auto;

  .container {
    max-width: 1800px;
    margin: 0 auto;
  }

  @include mobile {
    text-align: center;
  }

  h2 {
    margin-bottom: 24px;
  }

  p {
    margin: 0 0 30px;
    font: normal normal normal 18px/27px Poppins;
    letter-spacing: 0px;
    color: #dedede;
  }

  .accomplishments {
    display: flex;
    margin-bottom: 54px;
    gap: 64px;
    flex-wrap: wrap;

    @include mobile {
      align-items: center;
      gap: 24px;
    }
  }

  .success {
    @include mobile {
      width: 100%;
    }

    p {
      margin: 0;
      text-align: center;
      color: #dedede;

      &.number {
        font: normal normal normal 34px/43px Lora;
        letter-spacing: 0px;
      }

      &.item {
        font: normal normal medium 20px/30px Poppins;
        letter-spacing: 1.6px;
        text-transform: uppercase;
      }
    }
  }

  .externalButton {
    display: inline-block;
    padding: 16px 32px;
    font: normal normal medium 16px/25px Poppins;
    letter-spacing: 1.28px;
    color: #fbefd9;
    text-transform: uppercase;
    border: 1px solid #fbefd9;
    border-radius: 10px;
  }
}

.ability-tooltip-content {
  display: flex;
  justify-content: flex-start;
  padding: 5px;

  .ability-img {
    min-width: 35px;
    min-height: 35px;
    max-width: 35px;
    max-height: 35px;
    margin-right: 15px;

    img {
      width: 100%;
    }
  }

  .ability-info {
    .ability-name {
    }

    .ability-mana-row {
      display: flex;
      justify-content: space-between;
      min-width: 150px;
      margin-top: 5px;

      .mana-cost,
      .range {
        font-size: 12px;
      }
    }

    .ability-description {
      margin-top: 5px;
      font-size: 12px;
    }
  }
}

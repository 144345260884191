.news {
  max-width: 1600px;
  margin: 0 auto;

  .view-more-links {
    margin-top: 32px;
    text-align: center;
  }

  h2 {
    font-size: 24px;
    padding-top: 20px;
  }
  h4 {
    font-family: 'Poppins', sans-serif;
    color: white;
    opacity: 0.65;
    font-weight: thin;
  }
  .post-date {
    position: absolute;
    top: 28px;
    left: 28px;
    background-color: #fbe375;
    color: black;
    padding: 10px;
    border-radius: 5px;
  }
  .rtb-col {
    border: 1px solid #fbe375;
    border-radius: 15px;
    position: relative;
    padding: 40px 40px 120px !important;
    margin-bottom: 0px;
    max-width: 420px;
    background: #100f21;
    font-weight: 300;

    p {
      margin: 16px 0;
    }
  }
  .row {
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    justify-content: center;
    gap: 40px;
    margin-right: 0px;
    margin-left: 0px;
  }
  blockquote {
    margin-left: 0px;
    font-style: italic;
    font-family: 'Lora', serif;
    padding-left: 20px;
    border-left: 2px solid #fbe375;
  }
  .read-more {
    position: absolute;
    bottom: 40px;
    left: 0;
    width: 100%;
    text-align: center;
    margin-top: 40px;
  }
  .post-image {
    width: 100%;
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: cover;
    overflow: auto;
    display: block;
    border-radius: 5px;

    &:after {
      content: '';
      display: block;
      position: relative;
      margin-top: 60%;
      width: 100%;
      z-index: 1;
    }
  }
  #post-container {
    background: white;
    color: #100f21;
    margin-top: 40px;
    border-radius: 20px;
    outline: 1px solid #fbe375;
    outline-offset: 20px;
    padding: 40px;
  }
  #post-container h1,
  #post-container h2,
  #post-container h3,
  #post-container h4,
  #post-container h5 {
    color: #100f21;
  }
  #post-container .post-author {
    display: none;
  }
  #post-container h3 {
    padding-top: 20px;
  }
  #post-container h3:first-of-type {
    display: none;
  }
  #post-container a {
    text-decoration: underline;
    color: #100f21;
    opacity: 0.65;
  }
  #post-container a:hover {
    opacity: 0.4;
  }
  #post-container .row {
    grid-template-columns: 1fr;
  }
  #post-container .source-link {
    max-width: 100%;
    text-align: center;
  }
  #post-container .offset-sm-3 {
    margin-left: 0%;
  }
  .btn {
    border: 1px solid;
    font-size: 12px;
    letter-spacing: 2px;
    padding: 5px 15px;
  }

  @media (min-width: 576px) {
    #rtb-modal .col-sm-6,
    #rtb .col-sm-6 {
      flex: none;
    }
  }

  @media (max-width: 992px) {
    #post-container {
      padding: 10px 0px;
    }
    #rtb .row {
      grid-template-columns: 1fr;
    }
    #rtb-modal .col-sm-6,
    #rtb .col-sm-6 {
      margin: 0 auto;
    }
    h1 {
      font-size: 32px;
    }
  }

  @media (max-width: 500px) {
    .rtb-col {
      padding: 20px !important;
      max-width: 320px;
    }
  }
}

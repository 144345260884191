@import '../../../styles/base-refs';

.heroDetails {
  position: relative;
  padding: 70px 32px 0;
  background-color: $off-black;

  .copyHeader {
    max-width: 670px;
    margin: 0 auto;
    text-align: center;
  }

  h3 {
    @include header;
  }

  p {
    margin: 8px 0 24px;
    text-align: center;
    font: normal normal normal 18px/27px Poppins;
    letter-spacing: 0px;
    color: #dedede;
  }

  .heroDetailContainer {
    position: relative;
  }

  .heroRow {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;
    max-width: 1400px;
    padding: 90px 0 64px;
    margin: 0 auto;
    visibility: hidden;
    pointer-events: none;
    opacity: 0;
    transition: 0.2s opacity ease-in-out;

    &.active {
      visibility: visible;
      pointer-events: all;
      opacity: 1;
    }

    &.heroRowAbsolute {
      position: absolute;
      top: 0;
      left: 50%;
      width: 100%;
      transform: translateX(-50%);
    }

    &.archer {
      .avatars {
        position: relative;
        left: -20px;
        width: 373px;

        @include mobile {
          max-width: 110%;
        }
      }
    }

    &.knight {
      .avatars {
        width: 353px;
      }
    }

    &.priest {
      .avatars {
        width: 304px;

        @include mobile {
          max-width: 90%;
        }
      }
    }
  }

  .leftColumn {
    position: relative;
    width: 45%;

    @include mobile {
      display: none;
    }

    img {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 160%;
      max-width: 800px;
      transform: translate(-44%, -50%);
      pointer-events: none;
    }
  }

  .rightColumn {
    position: relative;
    width: 55%;

    @include mobile {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
    }
  }

  .heroThumbs {
    display: flex;
    align-items: flex-end;
    gap: 18px;

    button {
      padding: 0;
      border: 0;
      background-color: transparent;
      cursor: pointer;
      filter: grayscale(1);

      &.active {
        filter: grayscale(0);
      }

      img {
        width: 160px;
      }
    }
  }

  .heroInfoRow {
    display: flex;
    align-items: center;
    margin-top: 36px;

    @include mobile {
      justify-content: center;
    }

    .animation {
      width: 309px;
      height: 180px;
    }
  }

  .heroSkills {
    display: flex;
    align-items: center;
    margin-top: 36px;
    gap: 12px;

    img {
      width: 42px;
    }
  }

  .combatskills {
    margin: 8px 0 0;
    font: normal normal 500 18px/27px Poppins;
    letter-spacing: 2.7px;
    color: #dedede;
    text-transform: uppercase;
    text-align: left;
  }
}

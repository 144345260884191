@import '../../../styles/base-refs';

.adventuring {
  position: relative;
  max-width: 2000px;
  padding: 0 20px;
  margin: 24px auto 80px;

  @include mobile {
    padding: 24px;
  }

  h3 {
    margin-bottom: 6px;
    @include header;
  }

  h4 {
    margin-bottom: 6px;
    @include subheader;
  }

  p {
    @include bodytext;
  }

  .gamefiDetailsRow {
    display: flex;
    gap: 24px;
    height: 100%;

    +.gamefiDetailsRow {
      margin-top: 100px;
    }
  }

  .columnLeft {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1000px;

    @include mobile {
      display: none;
    }
  }

  .columnRight {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1;
    max-width: 1000px;
    min-width: 360px;

    img {
      max-width: 197px;
    }

    @include mobile {
      min-width: 0;
    }

    .copy {
      max-width: 420px;
      margin: 0 auto;
      text-align: center;
    }
  }

  .imageWrapper {
    position: relative;
    width: 340px;
  }
}

.pvp {
  position: relative;
  display: flex;
  justify-content: center;
  gap: 24px;
  padding: 54px 32px 24px;
  max-width: 1800px;
  margin: 0 auto 80px;

  @include mobile {
    padding: 24px;
  }

  h3 {
    margin-bottom: 6px;
    @include header;
  }

  h4 {
    margin-bottom: 6px;
    @include subheader;
  }

  p {
    @include bodytext;
  }

  .columnLeft {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1;
    max-width: 900px;
    min-width: 360px;

    @include mobile {
      min-width: 0;
    }

    .copy {
      max-width: 420px;
      margin: 0 auto;
      text-align: center;
    }
  }

  .columnRight {
    display: flex;
    align-items: center;
    max-width: 900px;

    @include mobile {
      display: none;
    }
  }
}

.tutorialContainer,
.grantsContainer,
.socialContainer {
  background-image: url('./img/tutorial/overworld-bg.png');
  background-size: cover;
  height: 100vh;
  width: 100vw;
  image-rendering: -moz-crisp-edges;
  image-rendering: pixelated;

  ::-webkit-scrollbar {
    width: 6px;
  }

  ::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0.1);
    border-radius: 6px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 6px;
  }

  img {
    max-width: 100%;
    image-rendering: auto;
  }
  a {
    color: #308e06;
  }
  a:hover {
    color: #6fbe08;
  }
  h1,
  h2,
  h3 {
    font-family: 'Lora', serif;
    font-weight: normal;
    color: #8e4800;
  }
  h2 {
    text-align: center;
    font-size: 28px;
    margin-bottom: 10px;
  }
  p,
  li {
    &:first-of-type {
      margin-top: 14px;
    }

    font-family: 'Poppins', serif;
    font-size: 14px;
    line-height: 1.5;
    color: black;
  }
}
.dfkProfile {
  position: fixed;
  top: 20px;
  left: 20px;
}
.dfkMenu {
  position: fixed;
  top: 20px;
  right: 20px;
}
.tutorialOverlay {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.65);
  position: relative;
}
.tutorialModal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 700px;
  min-width: 300px;
  width: 100%;
  max-height: 90vh;
  background-image: url('./img/tutorial/quest-border-left.png'),
    url('./img/tutorial/quest-border-right.png');
  background-position: left center, right center;
  background-repeat: repeat-y, repeat-y;
  background-color: #ffe0b7;
  transition: all 0.2s ease;
}
.tutorialModal:before,
.tutorialModal:after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  display: block;
}
.tutorialModal:before {
  background-image: url('./img/tutorial/quest-border-top.png'),
    url('./img/tutorial/quest-border-bottom.png');
  background-position: top center, bottom center;
  background-repeat: repeat-x, repeat-x;
  top: -2px;
  height: calc(100% + 12px);
}
.tutorialModal:after {
  background-image: url('./img/tutorial/quest-border-cornerTopLeft.png'),
    url('./img/tutorial/quest-border-cornerTopRight.png'),
    url('./img/tutorial/quest-border-cornerBtmRight.png'),
    url('./img/tutorial/quest-border-cornerBtmLeft.png');
  background-position: top left, top right, bottom right, bottom left;
  background-repeat: no-repeat;
  width: calc(100% + 4px);
  height: calc(100% + 12px);
  top: -2px;
  left: -2px;
}
.tutorialContent {
  padding: 40px;
  position: relative;
  z-index: 1;
  min-height: 200px;
}
.tutorialContent h2:first-of-type {
  margin-top: 0px;
}
.fancyModal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 700px;
  min-width: 300px;
  width: 100%;
  max-height: 90vh;
  background-image: url('./img/gui/basic_left.png'), url('./img/gui/basic_right.png');
  background-position: left center, right center;
  background-repeat: repeat-y, repeat-y;
  background-color: #ffe3bd;
  transition: all 0.2s ease;
  border-radius: 20px;
}
.fancyModal:before,
.fancyModal:after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  display: block;
}
.fancyModal:before {
  background-image: url('./img/gui/basic_top.png'), url('./img/gui/basic_bottom.png');
  background-position: top center, bottom center;
  background-repeat: repeat-x, repeat-x;
  top: 0px;
  height: 100%;
  border-radius: 20px;
}
.fancyModal:after {
  background-image: url('./img/gui/basic_top_left.png'), url('./img/gui/basic_top_right.png'),
    url('./img/gui/basic_bottom_right.png'), url('./img/gui/basic_bottom_left.png');
  background-position: top left, top right, bottom right, bottom left;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
}
.fancyTitle {
  background-image: url('./img/gui/gui_title_middle.png');
  background-repeat: repeat-x;
  position: relative;
  display: flex;
  max-width: 70%;
  height: 70px;
  align-items: center;
  justify-content: center;
  margin: -24px auto 0;
  font-family: 'Lora', serif;
  font-size: 28px;
  font-weight: bold;
  color: black;
}
.fancyTitle:before {
  content: '';
  display: block;
  position: absolute;
  width: calc(100% + 50px);
  height: 100%;
  left: -25px;
  top: 0;
  background-image: url('./img/gui/gui_title_left.png'), url('./img/gui/gui_title_right.png');
  background-repeat: no-repeat;
  background-position: top left, top right;
}
.pixelButton {
  background-image: url('./img/tutorial/pixel-button.png');
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center;
  min-width: 180px;
  padding: 12px 10px;
  display: inline-block;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  font-family: 'Lora', serif;
  font-size: 18px;
  color: #f9dc5c;
  cursor: pointer;
}
.pixelButton2 {
  background-image: url('./img/tutorial/pixel-button-2Center.png');
  background-repeat: repeat-x;
  height: 47px;
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  max-width: 70%;
  margin-left: 18px;
  margin-right: 18px;
  font-size: 14px;
  cursor: pointer;
  padding: 0px 10px;
  white-space: nowrap;
}
.pixelButton2 img {
  width: 16px;
  position: relative;
  left: 2px;
  transition: all 0.2s ease-in-out;
}
.pixelButton2:hover img {
  left: 6px;
}
.pixelButton2:before {
  content: '';
  display: block;
  position: absolute;
  top: 0px;
  left: -17px;
  width: calc(100% + 36px);
  height: 100%;
  background-image: url('./img/tutorial/pixel-button-2Left.png'),
    url('./img/tutorial/pixel-button-2Right.png');
  background-repeat: no-repeat;
  background-position: left center, right center;
}
.pixelButton2.back img {
  transform: scale(-1);
  left: -2px;
}
.pixelButton2.back:hover img {
  left: -6px;
}
.buttons {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  padding-top: 10px;
  gap: 10px;
  align-items: center;
}
p:first-of-type {
  margin-top: 0px;
}

.tutorialModal {
  top: 60%;
  visibility: hidden;
  opacity: 0;
  transition: all 0.4s ease;
}
.show {
  top: 50%;
  visibility: visible;
  opacity: 1;
  transition: all 0.4s ease;
}
.tutorialModal.show {
  z-index: 5;
}
.extraLink {
  position: relative;
  top: 30px;
  font-size: 12px;
}
.extraLink a {
  color: white;
}

.pagination {
  font-size: 12px;
  color: #8e4800;
  font-family: 'Lora', serif;
}
.pageBlock {
  display: inline-block;
  margin: 0px 1px;
  height: 4px;
  width: 10px;
  background: rgba(0, 0, 0, 0.1);
}
.pageBlock.selected {
  background: rgba(0, 0, 0, 0.4);
}
.scroll {
  overflow-y: auto;
  max-height: calc(90vh - 170px);
}
.gridSpecial {
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 20px;
}
.gridSpecial2 {
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 20px;
}
.grid2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}
.vCenter {
  align-items: center;
}
.vEnd {
  align-items: flex-end;
}
.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}
.nolan img {
  image-rendering: -moz-crisp-edges;
  image-rendering: pixelated;
  width: 162px;
}
.tourImg img {
  width: 200px;
}

.browser {
  max-height: 36px;
  margin-right: 10px;
}
.metamask {
  max-height: 28px;
  margin-top: 20px;
}
.harmony {
  height: 24px;
  margin-top: 20px;
}

.simpleSlideshow {
  text-align: center;
  position: relative;
  height: 200px;
  position: relative;
}
.dots {
  position: absolute;
  bottom: 0px;
  left: 50%;
  transform: translateX(-50%);
}
.dot {
  cursor: pointer;
  height: 8px;
  width: 8px;
  margin: 0 2px;
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  display: inline-block;
  transition: background-color 0.6s ease;
}
.dot.active,
.dot:hover {
  background-color: rgba(0, 0, 0, 0.3);
}

@media (max-width: 768px) {
  .tutorialModal {
    min-width: 200px;
  }
  .nolan img {
    width: 108px;
  }
  .gridSpecial2 {
    display: grid;
    grid-template-columns: 1fr;
    gap: 20px;
  }
  .tourImg,
  .nolan {
    text-align: center;
  }
}
@media (max-width: 500px) {
  .tutorialOverlay {
    overflow-y: auto;
    overflow-x: hidden;
  }
  .tutorialModal {
    max-height: none;
    top: 0;
    left: 0;
    transform: none;
    margin-bottom: 50px;
  }
  .tutorialContent {
    padding: 100px 40px;
  }
  .buttons {
    grid-template-columns: 1fr;
  }
  .buttons > div {
    text-align: center;
  }
  .scroll {
    max-height: 100%;
  }
}

.crystalvaleLanding {
  padding: 0px;
  margin: 0px;
  background-color: #fffdef;
  font-family: 'Poppins', sans-serif;
  font-size: 18px;
  font-weight: 300;
  color: #254263;

  a {
    color: #5da3cd;
    text-decoration: none;
  }
  img {
    max-width: 100%;
  }
  h1,
  h2,
  h3 {
    font-family: 'Lora', serif;
    font-weight: normal;
    color: #254263;
    margin: 0;
  }
  h1 {
    font-size: 50px;
  }
  h2 {
    font-size: 30px;
  }
  p {
    color: #254263;
  }
  .container {
    max-width: 1200px;
    margin: 0 auto;
  }
  .textCenter {
    text-align: center;
  }
  @media (max-width: 500px) {
    .mobileLeft {
      text-align: left !important;
    }
  }
  .primary {
    display: inline-block;
    position: relative;
    background-image: url('./img/cv-btn-center.png');
    background-repeat: repeat-x;
    height: 68px;
    line-height: 68px;
    font-size: 30px;
    font-family: 'Lora', serif;
    font-weight: bold;
    color: #254263;
    padding: 0px 40px;
    image-rendering: -moz-crisp-edges;
    image-rendering: pixelated;
  }
  .primary:before {
    content: '';
    position: absolute;
    top: 0;
    left: -40px;
    width: calc(100% + 80px);
    height: 100%;
    background-image: url('./img/cv-btn-left.png'), url('./img/cv-btn-right.png');
    background-repeat: no-repeat;
    background-position: top left, top right;
    image-rendering: -moz-crisp-edges;
    image-rendering: pixelated;
  }
  .primary:hover {
    background-image: url('./img/cv-btn-center-hover.png');
  }
  .primary:hover:before {
    background-image: url('./img/cv-btn-left-hover.png'), url('./img/cv-btn-right-hover.png');
  }
  .sharp {
    image-rendering: -moz-crisp-edges;
    image-rendering: pixelated;
  }

  @media (max-width: 500px) {
    .instructions img {
      transform: scale(2);
      margin: 36px 0px;
    }
  }

  .harmony {
    height: auto;
  }

  /**
    LIGHT AND DARK THEME
    **/
  .theme {
    position: fixed;
    top: 30px;
    left: 30px;
    z-index: 5;
  }
  .btn-toggle {
    width: 50px;
    height: 50px;
    background-color: #fffdef;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  .btn-toggle img {
    width: 35px;
  }
  .dark-theme {
    display: none;
  }

  /**
    HERO
    **/
  .hero {
    position: relative;
    display: block;
    background-image: url('./img/crystalvale_map_originalSize.gif');
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    image-rendering: -moz-crisp-edges;
    image-rendering: pixelated;
    padding: 100px 30px;
    height: auto;
    overflow: hidden;
  }
  .hero:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;
    background-color: #fffdef;
    opacity: 0.9;
  }
  .hero:after {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    background-image: url('./img/cv_cloud_left.gif'), url('./img/cv_cloud_right.gif');
    background-repeat: no-repeat;
    background-position: top left, top right;
    background-size: auto 100%;
  }
  .hero .logo,
  .hero .primary,
  .hero .tokenLogos {
    position: relative;
    z-index: 2;
  }
  .hero .logo {
    image-rendering: -moz-crisp-edges;
    image-rendering: pixelated;
    padding-bottom: 30px;
  }
  .hero .tokenLogos img {
    width: 50px;
    margin: 0 10px;
  }
  .hero .tokenLogos {
    padding-top: 30px;
  }
  @media (max-width: 768px) {
    .hero:after {
      background-position: top left -100px, top right -100px;
    }
  }

  /**
    HOW TO
    **/
  .howTo {
    position: relative;
    background: rgb(216, 249, 245);
    background: linear-gradient(180deg, rgba(216, 249, 245, 1) 0%, rgba(170, 231, 242, 1) 100%);
    padding: 100px 30px;
  }
  .howTo:before {
    content: '';
    display: block;
    position: absolute;
    top: -190px;
    left: 0px;
    width: 100%;
    height: 196px;
    background-image: url('./img/clouds-left.png'), url('./img/clouds-right.png');
    background-repeat: no-repeat;
    background-position: bottom left, bottom right;
    z-index: 1;
  }
  .howTo:after {
    content: '';
    display: block;
    position: absolute;
    top: -64px;
    left: 0px;
    background-image: url('./img/divider-lightBlue.png');
    background-repeat: repeat-x;
    background-position: top center;
    height: 70px;
    width: 100%;
  }
  .howTo .choices {
    display: flex;
    justify-content: center;
    gap: 20px;
    padding: 30px 0;
  }
  .howTo .choice {
    width: 120px;
    height: 122px;
    margin-top: 0;
    image-rendering: -moz-crisp-edges;
    image-rendering: pixelated;
    background-image: url('./img/crystal_infusion_item_frame_native_cv_x2.png');
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0.5;
  }
  .howTo .choice:hover {
    cursor: pointer;
  }
  .howTo .choice.unknown {
    font-size: 70px;
    font-family: 'Lora', serif;
    font-weight: bold;
  }
  .howTo .choice.active {
    opacity: 1;
  }
  .choice img {
    max-width: 60%;
  }
  .howTo .instructions {
    max-width: 700px;
    margin: 0 auto;
    visibility: hidden;
    opacity: 0;
    max-height: 0px;
  }
  .howTo .instructions.active {
    opacity: 1;
    visibility: visible;
    max-height: 5000px;
  }
  .howTo .instructions h2 {
    padding-top: 10px;
  }

  /**
    TOKENS
    **/
  .tokens {
    background-image: url('./img/cv-mountains-left.png'), url('./img/cv-mountains-right.png');
    background-repeat: no-repeat;
    background-position: top 70% left -100px, top 30% right -200px;
    background-size: auto;
    image-rendering: -moz-crisp-edges;
    image-rendering: pixelated;
    padding: 20px 30px;
    position: relative;
  }
  .tokens:before {
    content: '';
    display: block;
    position: absolute;
    top: -34px;
    left: 0px;
    width: 100%;
    background-image: url('./img/divider-cream.png');
    background-repeat: repeat-x;
    background-size: auto;
    background-position: center;
    height: 86px;
  }
  .tokens .tokenImg {
    width: 307px;
    height: 307px;
    background-color: rgba(220, 212, 186, 0.5);
    border-radius: 50%;
    padding: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    image-rendering: -moz-crisp-edges;
    image-rendering: pixelated;
  }
  .tokens .token {
    position: relative;
    display: grid;
    grid-template-columns: auto 1fr;
    gap: 100px;
    align-items: center;
    padding: 80px 0px;
  }
  .tokens .tokenRight .tokenImg {
    order: 2;
  }

  @media (max-width: 768px) {
    .tokens {
      background-image: none;
    }
    .tokens .token {
      grid-template-columns: 1fr;
      gap: 50px;
      padding: 40px 0px;
    }
    .tokenImg {
      order: 1;
      margin: 0 auto;
    }
    .tokenDescription {
      order: 2;
    }
  }

  /**
    ZONES
    **/
  .slideContent {
    position: relative;
    margin: 0px 1.25rem;
    height: 80vh;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    image-rendering: -moz-crisp-edges;
    image-rendering: pixelated;
    color: #fffdef;
  }
  .slideContent:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgb(37, 66, 99);
    background: linear-gradient(0deg, rgba(37, 66, 99, 0.9) 0%, rgba(93, 163, 205, 0.25) 100%);
  }
  .slide1 {
    background-image: url('./img/crystalvale_dock.gif');
  }
  .slide2 {
    background-image: url('./img/crystalvale_marketplace.gif');
  }
  .slide3 {
    background-image: url('./img/crystalvale_gardens.gif');
  }
  .slide4 {
    background-image: url('./img/crystalvale_bank.png');
  }
  .zones .zoneSlides .slide .caption {
    position: absolute;
    bottom: 80px;
    left: 60px;
    max-width: 600px;
    opacity: 0;
    transition: all 0.6s ease;
    transition-delay: 0.3s;
    z-index: 1;
  }
  .zones .zoneSlides .slick-active .slide .caption {
    bottom: 60px;
    opacity: 1;
    transition: all 0.6s ease;
    transition-delay: 0.3s;
  }
  .zones .zoneSlides .slide .caption h1 {
    font-weight: bold;
    color: white;
    padding-bottom: 20px;
  }
  .slick-arrow,
  .slick-prev:hover,
  .slick-prev:focus,
  .slick-next:hover,
  .slick-next:focus {
    border: 0px;
    background-image: url('./img/cv-arrow.png');
    background-size: 100%;
    background-color: transparent;
    image-rendering: -moz-crisp-edges;
    image-rendering: pixelated;
    width: 36px;
    height: 30px;
    color: transparent;
    overflow: hidden;
    position: absolute;
    top: 50%;
    z-index: 2;

    &:before {
      display: none;
    }

    &:active {
      display: block;
    }
  }
  .slick-prev {
    left: 60px;
    transform: translateY(-50%) scale(1.5);
  }
  .slick-prev:hover {
    cursor: pointer;
    transform: translateY(-50%) scale(1.7);
  }
  .slick-next {
    right: 60px;
    transform: translateY(-50%) rotate(180deg) scale(1.5);
  }
  .slick-next:hover {
    cursor: pointer;
    transform: translateY(-50%) rotate(180deg) scale(1.7);
  }

  .zonesBottom {
    position: relative;
    background-image: url('./img/crystalvale_map_originalSize.gif');
    background-repeat: no-repeat;
    background-position: top center;
    background-size: cover;
    image-rendering: -moz-crisp-edges;
    image-rendering: pixelated;
    padding-top: 100px;
    padding-bottom: 450px;
  }
  .zonesBottom:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgb(255, 253, 239);
    background: linear-gradient(180deg, rgba(255, 253, 239, 1) 4%, rgba(255, 253, 239, 0) 92%);
  }
  .zonesBottom .primary {
    position: relative;
  }

  @media (max-width: 768px) {
    .zones .zoneSlides .slide .caption {
      max-width: 450px;
    }
  }
  @media (max-width: 450px) {
    .zones .zoneSlides .slide .caption {
      max-width: 80%;
      left: 30px;
      font-size: 14px;
    }
    .zones .zoneSlides .slick-active.slide .caption {
      bottom: 30px;
    }
    .zones h1 {
      font-size: 30px;
    }
  }

  /**
    AIRDROPS
    **/
  .airdrops {
    position: relative;
    padding: 100px 30px;
    background-color: #254263;
    color: #fffdef;
  }
  .airdrops:before {
    content: '';
    display: block;
    position: absolute;
    top: -34px;
    left: 0px;
    width: 100%;
    background-image: url('./img/divider-blue.png');
    background-repeat: repeat-x;
    background-size: auto;
    height: 86px;
  }
  .airdrops h1 {
    padding-bottom: 100px;
  }
  .airdropGrid {
    display: flex;
    gap: 60px;
    flex-wrap: wrap;
    justify-content: center;
  }
  .airdropContainer {
    position: relative;
    max-width: 360px;
    border: 4px solid #5da3cd;
    border-radius: 6px;
    padding: 40px;
    font-size: 14px;
    background: #1d2d4d;

    p {
      line-height: 1.5;
      color: white;

      &:first-of-type {
        margin-top: 14px;
      }
    }
  }
  .airdropContainer:before {
    content: '';
    position: absolute;
    top: -31px;
    left: 0px;
    height: calc(100% + 62px);
    width: 100%;
    background-image: url('./img/airdrop-embellishment.png'),
      url('./img/airdrop-embellishment2.png');
    background-repeat: no-repeat;
    background-position: top center, bottom center;
    image-rendering: -moz-crisp-edges;
    image-rendering: pixelated;
  }
  .airdropContent {
    position: relative;
    z-index: 2;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .airdropContainer .title {
    font-family: 'Lora', serif;
    font-size: 24px;
  }
  .rewards {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 10px;
    padding-top: 16px;
  }
  .rewardBox {
    width: 66px;
    height: 67px;
    background-image: url('./img/crystal_infusion_item_frame_native_cv_x2.png');
    background-size: 100%;
    background-repeat: no-repeat;
    image-rendering: -moz-crisp-edges;
    image-rendering: pixelated;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .viewSnapshot {
    display: block;
    border: 4px solid #5da3cd;
    background: rgba(93, 163, 205, 0.25);
    border-radius: 6px;
    text-align: center;
    text-transform: uppercase;
    font-weight: bold;
    color: #fffdef;
    padding: 10px;
    letter-spacing: 2px;
    transition: all 0.2s ease;
  }
  .viewSnapshot:hover {
    background: rgba(93, 163, 205, 0.5);
  }
  .viewSnapshot.disabled {
    opacity: 0.4;
    cursor: unset;
  }
  .viewSnapshot.disabled:hover {
    background: rgba(93, 163, 205, 0.25);
  }
  .airdropStatus {
    padding-top: 10px;
  }
  .airdropStatus.no {
    color: #ff848b;
  }
  .airdropStatus.yes {
    color: #b3cf6b;
  }
  .airdropStatus.partial {
    color: #f9934e;
  }
  .airdropContent ul {
    margin: 0;
    padding: 0;
    list-style: none;
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  .airdropContent li:nth-child(even) {
    text-align: right;
  }

  .common {
    color: #fffdef;
  }
  .uncommon {
    color: #b3cf6b;
  }
  .rare {
    color: #5da3cd;
  }
  .legendary {
    color: #f9934e;
  }
  .mythic {
    color: #ba6feb;
  }

  /**
    FOOTER
    **/
  footer {
    padding: 100px 30px;
    background-color: #254263;
    color: #fffdef;
    background-image: url('./img/divider-embellishment.png');
    background-repeat: no-repeat;
    background-position: top center;
    background-size: auto;
    image-rendering: -moz-crisp-edges;
    image-rendering: pixelated;
  }
  footer .primary {
    margin-top: 20px;
  }

  /****
    SOCIAL
    ****/
  .social {
    padding-top: 60px;
  }
  .social a {
    color: #5da3cd;
    text-align: left;
    font-size: 30px;
    margin-right: 20px;
  }
  .social a:hover {
    text-decoration: none;
    color: #8ad7ed;
  }
  .social li {
    display: inline-block;
  }

  /**
    WARNING FRAME
    **/
  .warningFrame {
    position: relative;
    width: 100%;
    background-image: url('./img/warning_border.png'), url('./img/warning_border.png');
    background-position: top left, top right;
    background-repeat: repeat-y;
    margin: 20px 0px;
    font-size: 16px;
    font-style: italic;
  }
  .warningFrame:before,
  .warningFrame::after {
    content: '';
    display: block;
    position: absolute;
    top: -4px;
  }
  .warningFrame:before {
    left: 0px;
    width: 100%;
    height: calc(100% + 8px);
    background-image: url('./img/warning_top_left.png'), url('./img/warning_top_right.png'),
      url('./img/warning_bottom_left.png'), url('./img/warning_bottom_right.png');
    background-position: top left, top right, bottom left, bottom right;
    background-repeat: no-repeat;
  }
  .warningFrame:after {
    left: 50%;
    transform: translateX(-50%);
    width: calc(100% - 8px);
    height: calc(100% + 8px);
    background-image: url('./img/warning_border.png'), url('./img/warning_border.png');
    background-position: top left, bottom left;
    background-repeat: repeat-x;
  }
  .warningFrameContent {
    position: relative;
    padding: 20px 50px;
  }
  .warningFrameEmbellishments {
    position: relative;
  }
  .warningFrameEmbellishments:before,
  .warningFrameEmbellishments:after {
    content: '';
    position: absolute;
    display: block;
  }
  .warningFrameEmbellishments:before {
    top: 0;
    left: -26px;
    height: 100%;
    width: calc(100% + 52px);
    background: url('./img/warning_left.png'), url('./img/warning_right.png');
    background-repeat: no-repeat;
    background-position: center left, center right;
  }

  /**
    DARK MODE
    **/
  &.dark .tokens,
  &.dark .zones,
  &.dark {
    background-color: #254263;
    color: #fffdef;

    h1,
    h2,
    h3,
    p {
      color: #fffdef;
    }
  }
  &.dark .zonesBottom:before {
    background: rgb(37, 66, 99);
    background: linear-gradient(180deg, rgba(37, 66, 99, 1) 4%, rgba(37, 66, 99, 0) 92%);
  }
  &.dark .tokens:before {
    background-image: url('./img/divider-blue.png');
  }
  &.dark .hero:before {
    background-color: #254263;
  }
  &.dark .tokens .tokenImg {
    background-color: rgba(29, 45, 77, 0.5);
  }
  &.dark .howTo {
    color: #254263;
  }
  &.dark .btn-toggle {
    background-color: #254263;
    color: #fffdef;
  }
  &.dark .dark-theme {
    display: block;
  }
  &.dark .light-theme {
    display: none;
  }

  @media (prefers-color-scheme: dark) {
    &.dark .tokens,
    &.dark .zones,
    &.dark {
      background-color: #254263;
      color: #fffdef;
    }
    &.dark .zonesBottom:before {
      background: rgb(37, 66, 99);
      background: linear-gradient(180deg, rgba(37, 66, 99, 1) 4%, rgba(37, 66, 99, 0) 92%);
    }
    &.dark .tokens:before {
      background-image: url('./img/divider-blue.png');
    }
    &.dark .hero:before {
      background-color: #254263;
    }
    &.dark .tokens .tokenImg {
      background-color: rgba(29, 45, 77, 0.5);
    }
    &.dark .howTo {
      color: #254263;
    }
    &.dark .btn-toggle {
      background-color: #254263;
      color: #fffdef;
    }
    &.dark .dark-theme {
      display: block;
    }
    &.dark .light-theme {
      display: none;
    }
  }

  .tokens,
  .zones,
  body,
  .hero:before,
  .zonesBottom:before,
  .tokens:before,
  .tokens .tokenImg,
  .howTo {
    transition: all 0.2s ease-in-out;
  }
}

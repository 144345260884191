@import '../styles/base-refs';

.bigButton {
  padding: 24px 0;
  border: 2px solid #91d565;
  border-radius: 10px;
  font-family: $sans-serif-font;
  font-size: 24px;
  font-weight: 500;
  letter-spacing: 0px;
  min-width: 240px;
  text-align: center;
  line-height: 1;
  cursor: pointer;
  color: #91d565;
  background: #00000096 0% 0% no-repeat padding-box;
  opacity: 0.9;
  transition: 0.2s all ease-in-out;

  &:hover {
    color: #91d565;
    opacity: 1;
  }

  &.blue {
    border: 2px solid #91cce5;
    color: #91cce5;

    &:hover {
      color: #91cce5;
    }
  }

  &.lightBlue {
    border: 2px solid #5FC9EF;
    color: #5FC9EF;

    &:hover {
      color: #5FC9EF;
    }
  }
}

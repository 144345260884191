@import '../../styles/base-refs';

.centerSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 430px;
  padding: 0 12px;
  margin: 0 auto;
  text-align: center;

  .copy {
    max-width: 800px;
    margin: 0 auto;
  }

  h2 {
    margin: 0 0 12px;
    font: normal normal 500 32px/48px Poppins;
    letter-spacing: 4.8px;
    color: #dedede;
    text-transform: uppercase;

    @include mobile {
      font-size: 24px;
      line-height: 28px;
    }
  }

  p {
    margin-bottom: 12px;
    line-height: 32px;

    @include mobile {
      line-height: 22px;
    }

    &:last-of-type {
      margin-bottom: 0;
    }
  }
}

.playButton {
  display: inline-block;
  padding: 24px 0;
  margin-top: 32px;
  border: 2px solid #91d565;
  border-radius: 10px;
  font-family: $sans-serif-font;
  font-size: 24px;
  font-weight: 500;
  letter-spacing: 0px;
  min-width: 240px;
  text-align: center;
  line-height: 1;
  cursor: pointer;
  color: #dedede;
  opacity: 0.9;
  transition: 0.2s opacity ease-in-out;

  &:hover {
    opacity: 1;
  }
}

.playButton {
  background: transparent linear-gradient(180deg, #6db75c 0%, #4b8a4d 100%) 0% 0% no-repeat padding-box;
  box-shadow: inset 0px 3px 6px #00000029, 2px 0px 10px #ebfea965;
}

@import '../../styles/base-refs';

.sectionHeader {
  margin-bottom: 12px;
  font: normal normal 500 32px/48px Poppins;
  letter-spacing: 4.8px;
  color: #dedede;
  text-transform: uppercase;

  @include mobile {
    font-size: 24px;
    line-height: 28px;
  }
}

$white: #fff;
$white-24: rgba($white, 0.24);
$white-54: rgba($white, 0.54);
$off-white: #fff4e4;
$beige-fill: #f9f3ea;
$black: #000;
$off-black: #0b0b0b;
$black-54: rgba($black, 0.54);
$brown: #3e1f05;
$brown-dark: #29120a;
$brown-light: #4c3e23;
$brown-stroke: #321208;
$brown-stroke-light: #491306;
$gray-text-dark: #535353;
$brown-text-light: #733e2a;
$brown-text-lighter: #9b603a;
$brown-text-lightest: #c38c60;
$brown-text-dark: #481206;
$red-text-dark: #a62406;
$deep-blue: #100f21;
$deep-gray: #292929;
$green: #27ae60;
$green-54: rgba($green, 0.54);
$green-alt: #85a651;
$green-dark: #056c46;
$green-light: #bbca64;
$gold: #fac05d;
$gold-alt: #e9ad5a;
$gold-dark: #fba307;
$gold-dim: #5c4b2d;
$gold-light: #fbeb74;
$overlay-gray: rgba(30, 30, 30, 0.5);
$parchment: #ffe3bd;
$parchment-dark: #efcba2;
$parchment-darker: #daad82;
$parchment2: #fbddb8;
$parchment2-dark: #e1b890;
$parchment2-darker: #d3a474;
$parchment3: #feeacd;
$fancyBrown: #744e45;
$fancyBrownMid: #78544b;
$fancyBrownLight: #8d604e;
$fancyBrownDark: #4b2724;
$fancyBrownDarkalt: #452c27;
$fancyGray: #8e786d;
$fancyOrangeDark: #985c37;
$fancyOrange: #d9ac82;
$fancyOrangeLight: #eecaa1;
$fancyRedDark: #360e05;
$statBonusGreen: #14c25a;
$statGrowthBonusBlue: #21ccff;
$stat-text-brown: #522e23;
$stat-text-blue: #0b6d8a;
$stat-text-gold: #80632e;
$stat-text-gold-light: #af760b;
$stat-text-green: #0a602b;
$stat-text-purple: #8f1371;
$status-orange: #c7521f;
$status-red: #925230;
$status-green: #3d7737;
$status-purple: #85539d;
$red: #fd4040;

.socialContainer {
  background-attachment: fixed;

  .tutorialOverlay {
    overflow-y: auto;
    overflow-x: hidden;
    padding: 60px 10px;
  }

  .logo {
    image-rendering: -moz-crisp-edges;
    image-rendering: pixelated;
    max-width: 650px;
    margin: 0 auto;
  }

  .socialList {
    position: relative;
    top: 0;
    left: 0;
    transform: translate(0, 0);
    margin: 0 auto;
    max-height: inherit;
  }

  .spacing {
    height: 60px;
  }


  .social {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0;
    margin: 0;
    list-style: none;
    text-align: center;
  }

  .social a {
    text-decoration: none;
    color: #100f21;
    font-size: 18px;
    opacity: 1;
    transition: all 0.1s ease;
    display: block;
    text-align: center;
    padding: 16px;
    margin-right: 0;
  }

  .social a:hover {
    opacity: 0.5;
  }

  .social a::before {
    display: block;
    font-size: 32px;
  }

  @media (max-width: 768px) {
    .tutorialOverlay {
      padding: 30px 10px;
    }
  }
}

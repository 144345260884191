$beige-fill: #f9f3ea;
$brown-stroke: hwb(14 3% 80%);
$text-color: #744e45;

// Pulled from this resource: https://dev.to/codingdudecom/everything-about-stroke-text-in-css-561i
@mixin text-stroke($stroke-width, $stroke-color) {
  letter-spacing: calc($stroke-width / 3);
  text-shadow: calc($stroke-width * 1) calc($stroke-width * 0) 0 $stroke-color,
    calc($stroke-width * 0.9239) calc($stroke-width * 0.3827) 0 $stroke-color,
    calc($stroke-width * 0.7071) calc($stroke-width * 0.7071) 0 $stroke-color,
    calc($stroke-width * 0.3827) calc($stroke-width * 0.9239) 0 $stroke-color,
    calc($stroke-width * 0) calc($stroke-width * 1) 0 $stroke-color,
    calc($stroke-width * -0.3827) calc($stroke-width * 0.9239) 0 $stroke-color,
    calc($stroke-width * -0.7071) calc($stroke-width * 0.7071) 0 $stroke-color,
    calc($stroke-width * -0.9239) calc($stroke-width * 0.3827) 0 $stroke-color,
    calc($stroke-width * -1) calc($stroke-width * 0) 0 $stroke-color,
    calc($stroke-width * -0.9239) calc($stroke-width * -0.3827) 0 $stroke-color,
    calc($stroke-width * -0.7071) calc($stroke-width * -0.7071) 0 $stroke-color,
    calc($stroke-width * -0.3827) calc($stroke-width * -0.9239) 0 $stroke-color,
    calc($stroke-width * 0) calc($stroke-width * -1) 0 $stroke-color,
    calc($stroke-width * 0.3827) calc($stroke-width * -0.9239) 0 $stroke-color,
    calc($stroke-width * 0.7071) calc($stroke-width * -0.7071) 0 $stroke-color,
    calc($stroke-width * 0.9239) calc($stroke-width * -0.3827) 0 $stroke-color;
}

.serendaleLanding {
  padding: 0px;
  margin: 0px;
  background-color: #fffdef;
  font-family: 'Poppins', sans-serif;
  font-size: 18px;
  font-weight: 300;
  color: $brown-stroke;

  a {
    color: $text-color;
    text-decoration: underline;
    overflow-wrap: break-word;
  }

  img {
    max-width: 100%;
  }

  h1,
  h2,
  h3 {
    font-family: 'Lora', serif;
    font-weight: normal;
    color: #613e37;
    margin: 0;
  }

  h1 {
    font-size: 50px;
  }

  h2 {
    font-size: 30px;
  }

  p,
  li {
    color: $text-color;
  }

  .container {
    max-width: 1200px;
    margin: 0 auto;
  }

  .textCenter {
    text-align: center;
  }

  @media (max-width: 500px) {
    .mobileLeft {
      text-align: left !important;
    }
  }

  .primary {
    display: inline-block;
    position: relative;
    max-width: 400px;
    margin: 0 auto;
    background-image: url('./img/button/btn_middle.png');
    background-repeat: repeat-x;
    background-position: top center;
    height: 68px;
    line-height: 68px;
    font-size: 24px;
    font-family: 'Lora', serif;
    font-weight: bold;
    color: $beige-fill;
    @include text-stroke(3.5px, $brown-stroke);
    padding: 0px 40px;
    image-rendering: -moz-crisp-edges;
    image-rendering: pixelated;
    text-decoration: none;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: -40px;
      width: calc(100% + 80px);
      height: 100%;
      background-image: url('./img/button/btn_left.png'), url('./img/button/btn_right.png');
      background-repeat: no-repeat;
      background-position: top left, top right;
      image-rendering: -moz-crisp-edges;
      image-rendering: pixelated;
    }

    &:hover {
      background-image: url('./img/button/btn_middle_down.png');

      &:before {
        background-image: url('./img/button/btn_left_down.png'),
          url('./img/button/btn_right_down.png');
      }
    }
  }

  .sharp {
    image-rendering: -moz-crisp-edges;
    image-rendering: pixelated;
  }

  @media (max-width: 500px) {
    .instructions img {
      transform: scale(2);
      margin: 36px 0px;
    }
  }

  .harmony {
    height: auto;
  }

  /**
    HERO
    **/
  .hero {
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: column;
    background-image: url('./img/serendale-map.gif');
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    image-rendering: -moz-crisp-edges;
    image-rendering: pixelated;
    padding: 100px 30px;
    height: auto;
    overflow: hidden;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: block;
      background-color: #fffdef;
      opacity: 0.8;
    }

    &:after {
      content: '';
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      background-image: url('./img/sn_cloud_left.gif'), url('./img/sn_cloud_right.gif');
      background-repeat: no-repeat;
      background-position: top left, top right;
      background-size: auto 100%;
    }

    .logo {
      max-width: 858px;
      margin: 0 auto;
      image-rendering: -moz-crisp-edges;
      image-rendering: pixelated;
      padding-bottom: 30px;
    }

    .tokenLogos {
      padding-top: 30px;

      img {
        width: 50px;
        margin: 0 10px;
      }
    }
  }

  .hero .logo,
  .hero .primary,
  .hero .tokenLogos {
    position: relative;
    z-index: 2;
  }

  @media (max-width: 768px) {
    .hero:after {
      background-position: top left -100px, top right -100px;
    }
  }

  /**
    HOW TO
    **/
  .howTo {
    position: relative;
    background: rgb(241, 233, 222);
    background: linear-gradient(180deg, rgb(241, 233, 222) 0%, rgb(228, 220, 208) 100%);
    padding: 100px 30px;
  }

  .howTo:before {
    content: '';
    display: block;
    position: absolute;
    top: -190px;
    left: 0px;
    width: 100%;
    height: 196px;
    background-image: url('./img/clouds-left.png'), url('./img/clouds-right.png');
    background-repeat: no-repeat;
    background-position: bottom left, bottom right;
    z-index: 1;
  }

  .howTo:after {
    content: '';
    display: block;
    position: absolute;
    top: -64px;
    left: 0px;
    background-image: url('./img/divider-lightBlue.png');
    background-repeat: repeat-x;
    background-position: top center;
    height: 70px;
    width: 100%;
  }

  .howTo .choices {
    display: flex;
    justify-content: center;
    gap: 20px;
    padding: 30px 0;
  }

  .howTo .choice {
    width: 120px;
    height: 122px;
    margin-top: 0;
    image-rendering: -moz-crisp-edges;
    image-rendering: pixelated;
    background-image: url('./img/how-to-frame.png');
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0.5;
  }

  .howTo .choice:hover {
    cursor: pointer;
  }

  .howTo .choice.unknown {
    font-size: 70px;
    font-family: 'Lora', serif;
    font-weight: bold;
  }

  .howTo .choice.active {
    opacity: 1;
  }

  .choice img {
    max-width: 60%;
  }

  .howTo .instructions {
    max-width: 700px;
    margin: 0 auto;
    visibility: hidden;
    opacity: 0;
    max-height: 0px;
  }

  .howTo .instructions.active {
    opacity: 1;
    visibility: visible;
    max-height: 5000px;
  }

  .howTo .instructions h2 {
    padding-top: 10px;
  }

  /**
    TOKENS
    **/
  .tokens {
    background-image: url('./img/sn-mountains-left.png'), url('./img/sn-mountains-right.png');
    background-repeat: no-repeat;
    background-position: top 50% left -100px, top -18% right -200px;
    background-size: auto;
    image-rendering: -moz-crisp-edges;
    image-rendering: pixelated;
    padding: 20px 30px;
    position: relative;
    background-color: #ffe3bd;
  }

  .tokens:before {
    content: '';
    display: block;
    position: absolute;
    top: -34px;
    left: 0px;
    width: 100%;
    background-image: url('./img/divider-cream.png');
    background-repeat: repeat-x;
    background-size: auto;
    background-position: center;
    height: 86px;
  }

  .tokens .tokenImg {
    width: 307px;
    height: 307px;
    background-color: rgba(220, 212, 186, 0.5);
    border-radius: 50%;
    padding: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    image-rendering: -moz-crisp-edges;
    image-rendering: pixelated;

    &.kaia {
      img {
        width: 140px;
        z-index: 1;
      }

      &::before {
        content: '';
        background: #000000;
        height: 207px;
        width: 207px;
        position: absolute;
        border-radius: 50%;
      }
    }
  }

  .tokens .token {
    position: relative;
    display: grid;
    grid-template-columns: auto 1fr;
    gap: 100px;
    align-items: center;
    padding: 80px 0px;
  }

  .tokens .tokenRight .tokenImg {
    order: 2;
  }

  @media (max-width: 768px) {
    .tokens {
      background-image: none;
    }

    .tokens .token {
      grid-template-columns: 1fr;
      gap: 50px;
      padding: 40px 0px;
    }

    .tokenImg {
      order: 1;
      margin: 0 auto;
    }

    .tokenDescription {
      order: 2;
    }
  }

  /**
    ZONES
    **/
  .slideContent {
    position: relative;
    margin: 0px 1.25rem;
    height: 80vh;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    image-rendering: -moz-crisp-edges;
    image-rendering: pixelated;
    color: #fffdef;
  }

  .slideContent:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgb(50, 18, 8);
    background: linear-gradient(0deg, rgba(50, 18, 8, 0.7) 0%, rgb(50, 18, 8, 0) 100%);
  }

  .slide1 {
    background-image: url('./img/sd-dock.png');
  }

  .slide2 {
    background-image: url('./img/sd-marketplace.png');
  }

  .slide3 {
    background-image: url('./img/sd-gardens.png');
  }

  .slide4 {
    background-image: url('./img/sd-jeweler.png');
  }

  .slide5 {
    background-image: url('./img/sd-tavern.png');
  }

  .slide6 {
    background-image: url('./img/sd-portal.png');
  }

  .slide7 {
    background-image: url('./img/sd-professions.png');
  }

  .zones .zoneSlides .slide .caption {
    position: absolute;
    bottom: 80px;
    left: 60px;
    max-width: 600px;
    opacity: 0;
    transition: all 0.6s ease;
    transition-delay: 0.3s;
    z-index: 1;

    a {
      color: white;
    }
  }

  .zones .zoneSlides .slick-active .slide .caption {
    bottom: 60px;
    opacity: 1;
    transition: all 0.6s ease;
    transition-delay: 0.3s;
  }

  .zones .zoneSlides .slide .caption h1 {
    font-weight: bold;
    color: white;
    padding-bottom: 20px;
  }

  .slick-arrow,
  .slick-prev:hover,
  .slick-prev:focus,
  .slick-next:hover,
  .slick-next:focus {
    border: 0px;
    background-image: url('./img/arrow.png');
    background-size: 100%;
    background-color: transparent;
    image-rendering: -moz-crisp-edges;
    image-rendering: pixelated;
    width: 36px;
    height: 30px;
    color: transparent;
    overflow: hidden;
    position: absolute;
    top: 50%;
    z-index: 2;

    &:before {
      display: none;
    }

    &:active {
      display: block;
    }
  }

  .slick-prev {
    left: 60px;
    transform: translateY(-50%) scale(1.5);
  }

  .slick-prev:hover {
    cursor: pointer;
    transform: translateY(-50%) scale(1.7);
  }

  .slick-next {
    right: 60px;
    transform: translateY(-50%) rotate(180deg) scale(1.5);
  }

  .slick-next:hover {
    cursor: pointer;
    transform: translateY(-50%) rotate(180deg) scale(1.7);
  }

  .zonesBottom {
    position: relative;
    background-image: url('./img/serendale-map.gif');
    background-repeat: no-repeat;
    background-position: center 20%;
    background-size: cover;
    image-rendering: -moz-crisp-edges;
    image-rendering: pixelated;
    padding-top: 100px;
    padding-bottom: 450px;
  }

  .zonesBottom:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgb(255, 253, 239);
    background: linear-gradient(180deg, rgba(255, 253, 239, 1) 4%, rgba(255, 253, 239, 0) 92%);
  }

  .zonesBottom .primary {
    position: relative;
  }

  @media (max-width: 768px) {
    .zones .zoneSlides .slide .caption {
      max-width: 450px;
    }
  }

  @media (max-width: 450px) {
    .zones .zoneSlides .slide .caption {
      max-width: 80%;
      left: 30px;
      font-size: 14px;
    }

    .zones .zoneSlides .slick-active.slide .caption {
      bottom: 30px;
    }

    .zones h1 {
      font-size: 30px;
    }
  }

  /**
    FOOTER
    **/
  footer {
    padding: 100px 30px;
    background-color: rgb(255, 227, 189);
    color: #fffdef;
    background-image: url('./img/divider-embellishment.png');
    background-repeat: no-repeat;
    background-position: center 40px;
    background-size: auto;
    image-rendering: -moz-crisp-edges;
    image-rendering: pixelated;

    .primary {
      margin-top: 20px;
    }

    .icon {
      a {
        text-decoration: none;
      }
    }
  }

  /****
    SOCIAL
    ****/
  .social {
    display: flex;
    justify-content: center;
    margin: 0 auto;
    gap: 20px;
    padding-top: 40px;

    a {
      margin: 0;
      text-align: left;
      font-size: 30px;
      color: #744e45;
      opacity: 0.8;

      &:hover {
        text-decoration: none;
        opacity: 1;
      }
    }

    li {
      display: inline-block;
    }
  }

  /**
    WARNING FRAME
    **/
  .warningFrame {
    position: relative;
    width: 100%;
    background-image: url('./img/warning_border.png'), url('./img/warning_border.png');
    background-position: top left, top right;
    background-repeat: repeat-y;
    margin: 20px 0px;
    font-size: 16px;
    font-style: italic;

    &:before,
    &:after {
      content: '';
      display: block;
      position: absolute;
      top: -4px;
    }

    &:before {
      left: 0px;
      width: 100%;
      height: calc(100% + 8px);
      background-image: url('./img/warning_top_left.png'), url('./img/warning_top_right.png'),
        url('./img/warning_bottom_left.png'), url('./img/warning_bottom_right.png');
      background-position: top left, top right, bottom left, bottom right;
      background-repeat: no-repeat;
    }

    &:after {
      left: 50%;
      transform: translateX(-50%);
      width: calc(100% - 8px);
      height: calc(100% + 8px);
      background-image: url('./img/warning_border.png'), url('./img/warning_border.png');
      background-position: top left, bottom left;
      background-repeat: repeat-x;
    }
  }

  .warningFrameContent {
    position: relative;
    padding: 20px 50px;
  }

  .warningFrameEmbellishments {
    position: relative;

    &:before,
    &:after {
      content: '';
      position: absolute;
      display: block;
    }

    &:before {
      top: 0;
      left: -26px;
      height: 100%;
      width: calc(100% + 52px);
      background: url('./img/warning_left.png'), url('./img/warning_right.png');
      background-repeat: no-repeat;
      background-position: center left, center right;
    }
  }

  .tokens,
  .zones,
  body,
  .hero:before,
  .zonesBottom:before,
  .tokens:before,
  .tokens .tokenImg,
  .howTo {
    transition: all 0.2s ease-in-out;
  }
}

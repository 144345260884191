@import '../../styles/base-refs';

.leftSection {
  display: flex;

  img {
    width: 460px;

    @include mobile {
      max-width: 100%;
    }
  }
}

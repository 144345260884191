@import '../../styles/base-refs';

.nextSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 60px 12px 50px;
  gap: 7px;
  text-align: center;

  p {
    margin: 0;
    font: normal normal normal 34px/43px Lora;
    letter-spacing: 0px;
    color: #dedede;

    @include mobile {
      font-size: 24px;
      line-height: 28px;
    }
  }
}

@import '../styles/base-refs';

.coreAccordion {
  max-width: 1800px;
  margin: 0 auto;

  @media (min-width: 1500px) {
    min-height: 260px;
  }

  @include mobile {
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
    border-top: 1px solid rgba(255, 255, 255, 0.3);
  }

  .accordionRow {
    display: flex;
    position: relative;

    @include mobile {
      flex-wrap: wrap;
    }
  }

  .placeholder {
    img {
      width: 100%;
    }

    @include mobile {
      display: none;
    }
  }

  .accordionOption {
    position: absolute;
    width: 18.66666667%;
    padding: 0;
    border: 0;
    background-color: transparent;
    cursor: pointer;
    transition: 0.3s filter ease-in-out;

    &.inactive {
      filter: grayscale(1);
    }

    @include mobile {
      position: static;
      width: 33.33333333%;
    }

    @media (max-width: 560px) {
      width: 50%;
    }

    img {
      width: 100%;
      max-width: 320px;

      @include mobile {
        display: none;
      }
    }

    &:last-of-type {
      .textBlock {
        width: 98.5%;
      }
    }

    .textBlock {
      position: absolute;
      bottom: 0;
      left: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 90.5%;
      min-height: 72px;
      padding: 0 12px;
      background: #0000009d 0% 0% no-repeat padding-box;

      @include tablet {
        min-height: 60px;
      }

      @include mobile {
        position: static;
        width: 100%;
        min-height: 70px;
        background: transparent;
        flex-wrap: wrap;
      }

      p {
        margin: 0;
        font: normal normal 600 14px/20px Poppins;
        letter-spacing: 1.2px;
        color: #dedede;
        text-transform: uppercase;

        @include small-desktop {
          font-size: 12px;
          line-height: 16px;
        }

        @include tablet {
          font-size: 10px;
          line-height: 14px;
        }

        @include mobile {
          font-size: 12px;
        }
      }
    }
  }
}

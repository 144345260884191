.backgroundContainer {
  background-image: url(./img/cv-map-bg.gif);
  background-repeat: no-repeat;
  background-size: cover;
  font-family: 'Poppins', sans-serif;
  color: black;

  #CrystalValeTour {
    background-color: rgba(14, 83, 114, 0.64);
    align-items: flex-start;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .menuColumn {
    height: 100vh;
    max-width: 680px;
    width: 41.4887126%;
  }

  .buttonsContainer {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-top: 120px;
  }

  .menuButton {
    background: none;
    background-repeat: no-repeat;
    background-size: contain;
    border: 0;
    color: #fff;
    font-size: 28px;
    height: 80px;
    margin-bottom: 6px;
    text-transform: uppercase;
    width: 85%;
    cursor: pointer;

    &:hover {
      color: #fff;
    }
  }

  .menuButton.active {
    font-size: 32px;
    height: 95px;
    width: 100%;
  }

  .menuButton.buttonRed {
    background-image: url('./img/buttonRed.png');
  }

  .menuButton.buttonOrange {
    background-image: url('./img/buttonOrange.png');
  }

  .menuButton.buttonBlue {
    background-image: url('./img/buttonBlue.png');
  }

  .menuButton.buttonYellow {
    background-image: url('./img/buttonYellow.png');
  }

  .menuButton.buttonGreen {
    background-image: url('./img/buttonGreen.png');
  }

  .miniMapContainer {
    margin-top: 80px;
    width: 650px;
  }

  .minimapImg {
    display: none;
  }

  .minimapImg.active {
    display: block;
  }

  .miniMapContainer img {
    width: 100%;
  }

  .contentContainer {
    padding: 24px 0 24px 15px;
    max-width: 959px;
    width: 58.511287%;
  }

  .borderContainer {
    border: 10px solid #0b6e92;
  }

  .content {
    border: 32px solid #6dbdf6;
    background-color: #e8f5ff;
    display: none;
    font-size: 18px;
    height: calc(100% - 220px);
    max-height: 1022px;
    overflow-y: scroll;
    padding: 35px;
    text-align: center;
  }

  .content.active {
    display: block;
  }

  .content .bold {
    font-weight: 700;
  }

  .content .leftAlign {
    text-align: left;
  }

  .content .centered {
    margin: 0 auto;
    width: 585px;
  }

  .content.marketplace img {
    width: 655px;
  }

  .content.gardens > img {
    margin-top: 30px;
    width: 655px;
  }

  .content.gardens > p {
    margin: 0 auto;
    text-align: left;
    width: 625px;
  }

  .content.gardens > p.topSpacing {
    margin-top: 35px;
  }

  .content.jeweler > p {
    margin: 0 auto 30px;
    text-align: left;
    width: 613px;
  }

  .content.docks > p {
    margin: 0 auto 30px;
    text-align: left;
    width: 609px;
  }

  .content h2 {
    color: #4070b8;
    font-family: 'Lora', serif;
    font-size: 40px;
    font-weight: normal;
  }

  .sideBySide {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .sideBySide.imgLeft {
    flex-direction: row-reverse;
  }

  .sideBySide .textContainer {
    text-align: left;
    width: 60%;
  }

  .sideBySide.crystalValeMap .textContainer {
    margin-right: 35px;
    width: 310px;
  }

  .sideBySide.crystalValeMap .imageContainer {
    width: 303px;
  }

  .sideBySide.gameMenu {
    margin-top: 30px;
  }

  .sideBySide.gameMenu .textContainer {
    margin-left: 65px;
    width: 410px;
  }

  .sideBySide.gameMenu .imageContainer img {
    width: 151px;
  }

  .sideBySide.avatar {
    margin-top: 40px;
  }

  .sideBySide.avatar .textContainer {
    margin-right: 30px;
    width: 422px;
  }

  .sideBySide.avatar .imageContainer img {
    width: 176px;
  }

  .sideBySide.ragna .textContainer {
    margin-right: 30px;
    width: 450px;
  }

  .sideBySide.ragna .imageContainer img {
    width: 176px;
  }

  .sideBySide.tokens {
    align-items: flex-end;
  }

  .sideBySide.tokens .textContainer {
    margin-right: 0;
    width: 400px;
  }

  .sideBySide.tokens .paragraphSml {
    width: 385px;
  }

  .sideBySide.tokens .imageContainer {
    margin-bottom: 40px;
  }

  .sideBySide.tokens .imageContainer img {
    width: 205px;
  }

  .sideBySide.ulfur .textContainer {
    width: 390px;
  }

  .sideBySide.ulfur .imageContainer {
    margin-right: 20px;
  }

  .sideBySide.ulfur .imageContainer img {
    width: 203px;
  }

  .sideBySide.helga .textContainer {
    margin-right: 46px;
    width: 460px;
  }

  .sideBySide.helga .imageContainer img {
    width: 120px;
  }

  .sideBySide.buttons .textContainer {
    margin-right: 10px;
    text-align: left;
    width: 380px;
  }

  .sideBySide.buttons {
    margin-top: 30px;
  }

  .sideBySide.buttons img {
    width: 225px;
  }

  .sideBySide.soren {
    align-items: flex-start;
  }

  .sideBySide.soren .textContainer {
    margin-top: 40px;
    width: 428px;
  }

  .sideBySide.soren .imageContainer img {
    margin-right: 30px;
    width: 173px;
  }

  .sideBySide.jewelerRoom {
    align-items: flex-end;
  }

  .sideBySide.jewelerRoom .textContainer {
    margin: 0 30px 30px;
    width: 278px;
  }

  .sideBySide.veigar {
    margin: 30px 0;
  }

  .sideBySide.veigar .textContainer {
    margin-right: 30px;
    width: 450px;
  }

  .sideBySide.veigar .imageContainer img {
    width: 170px;
  }
  @media screen and (max-width: 1654px) {
    .buttonsContainer {
      margin: 120px auto 0;
      max-width: 597px;
    }
    .menuButton {
      height: 71px;
    }

    .menuButton.active {
      height: 83px;
    }
  }
  @media screen and (max-width: 1439px) {
    .menuColumn {
      max-width: 500px;
    }

    .menuButton {
      font-size: 24px;
      height: 60px;
    }

    .menuButton.active {
      font-size: 28px;
      height: 70px;
    }

    .miniMapContainer {
      width: 100%;
    }

    .contentContainer {
      max-width: 810px;
    }

    .content .centered {
      width: 90%;
    }

    .content.marketplace > img,
    .content.gardens > img,
    .content.jeweler > img,
    .content.docks > img,
    .content.gardens > p,
    .content.jeweler > p,
    .content.docks > p {
      width: 90%;
    }

    .sideBySide.crystalValeMap .textContainer {
      width: 46.069182%;
    }

    .sideBySide.crystalValeMap .imageContainer {
      width: 47.641509%;
    }

    .sideBySide.crystalValeMap .imageContainer img,
    .sideBySide.gameMenu .imageContainer img,
    .sideBySide.avatar .imageContainer img,
    .sideBySide.ragna .imageContainer img,
    .sideBySide.tokens .imageContainer img,
    .sideBySide.ulfur .imageContainer img,
    .sideBySide.helga .imageContainer img,
    .sideBySide.soren .imageContainer img,
    .sideBySide.jewelerRoom .imageContainer img,
    .sideBySide.veigar .imageContainer img {
      margin: 0;
      width: 100%;
    }

    .sideBySide.gameMenu .textContainer {
      width: 64.465408%;
    }

    .sideBySide.gameMenu .imageContainer {
      width: 23.742138%;
    }

    .sideBySide.avatar .textContainer {
      width: 66.352201%;
    }

    .sideBySide.avatar .imageContainer {
      width: 27.672955%;
    }

    .sideBySide.ragna .textContainer {
      width: 61%;
    }

    .sideBySide.ragna .imageContainer {
      width: 27.672955%;
    }

    .sideBySide.tokens .textContainer {
      width: 62.893081%;
    }

    .sideBySide.tokens .paragraphSml {
      width: 98%;
    }

    .sideBySide.tokens .imageContainer {
      width: 32.232704%;
    }

    .sideBySide.ulfur .textContainer {
      width: 61.320754%;
    }

    .sideBySide.ulfur .imageContainer {
      width: 31.918238%;
    }

    .sideBySide.helga .textContainer {
      width: 64%;
    }

    .sideBySide.helga .imageContainer {
      width: 18.867924%;
    }

    .sideBySide.buttons .textContainer {
      width: 53%;
    }

    .sideBySide.soren .textContainer {
      width: 62.7%;
    }
    .sideBySide.soren .imageContainer {
      margin-right: 30px;
      width: 27.201257%;
    }

    .sideBySide.jewelerRoom .textContainer {
      width: 50%;
    }

    .sideBySide.jewelerRoom .imageContainer {
      width: 50%;
    }

    .sideBySide.veigar .textContainer {
      width: 58%;
    }

    .sideBySide.veigar .imageContainer {
      width: 26.729559%;
    }
  }

  @media screen and (max-width: 1199px) {
    #CrystalValeTour {
      flex-direction: column;
      align-items: center;
    }

    .menuColumn {
      height: auto;
      max-width: 620px;
      width: 80%;
    }

    .buttonsContainer {
      margin-top: 30px;
    }

    .menuButton {
      font-size: 26px;
      height: 73px;
    }

    .menuButton.active {
      height: 86px;
      font-size: 30px;
    }

    .miniMapContainer {
      display: none;
    }

    .contentContainer {
      padding: 0;
      margin-top: 30px;
      width: 92%;
    }
  }

  @media screen and (max-width: 767px) {
    .menuColumn {
      width: 338px;
    }

    .menuButton {
      font-size: 20px;
      height: 40px;
    }

    .menuButton.active {
      font-size: 24px;
      height: 47px;
    }

    .content {
      border: 7px solid #6dbdf6;
    }

    .content .centered,
    .content.gardens > p,
    .content.jeweler > p,
    .content.docks > p {
      width: 96%;
    }

    .sideBySide,
    .sideBySide.imgLeft {
      flex-direction: column-reverse;
    }

    .sideBySide.crystalValeMap .textContainer,
    .sideBySide.gameMenu .textContainer,
    .sideBySide.avatar .textContainer {
      margin: 30px 0 0;
      width: 95%;
    }

    .sideBySide.crystalValeMap .imageContainer {
      width: 95%;
    }

    .sideBySide.gameMenu .imageContainer {
      width: 176px;
    }

    .sideBySide.avatar .imageContainer {
      width: 228px;
    }

    .sideBySide.ragna .textContainer {
      margin: 12px 0 0;
      width: 95%;
    }

    .sideBySide.ragna .imageContainer {
      margin-top: 30px;
      width: 150px;
    }

    .sideBySide.tokens {
      align-items: center;
    }
    .sideBySide.tokens .imageContainer {
      margin: 30px 0 0;
      width: 150px;
    }

    .sideBySide.tokens .textContainer,
    .sideBySide.ulfur .textContainer,
    .sideBySide.helga .textContainer,
    .sideBySide.soren .textContainer,
    .sideBySide.jewelerRoom .textContainer,
    .sideBySide.veigar .textContainer {
      margin: 12px 0 0;
      width: 95%;
    }

    .sideBySide.buttons .textContainer {
      margin: 12px 0 30px;
      width: 95%;
    }

    .sideBySide.tokens .paragraphSml {
      margin: 18px 0;
      width: 100%;
    }

    .sideBySide.ulfur .imageContainer {
      margin: 30px 0 0;
      width: 150px;
    }

    .sideBySide.helga .imageContainer {
      margin-top: 30px;
      width: 120px;
    }

    .sideBySide.buttons {
      flex-direction: column;
      margin-top: 0;
    }

    .sideBySide.buttons img {
      width: auto;
    }

    .sideBySide.soren {
      align-items: center;
    }

    .sideBySide.soren .imageContainer {
      margin: 30px 0 0;
      width: 150px;
    }

    .sideBySide.jewelerRoom {
      align-items: center;
    }

    .sideBySide.jewelerRoom .imageContainer {
      margin: 30px 0 0;
      width: 200px;
    }

    .sideBySide.veigar .imageContainer {
      margin: 30px 0 0;
      width: 150px;
    }
  }
}

@import '../../styles/base-refs';

.hero {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 350px;
  padding: 0 24px;
  text-align: center;

  @include mobile {
    height: auto;
    min-height: 300px;
    padding: 0 12px;
  }

  h2 {
    margin: 0 0 12px;
    font: normal normal 600 40px/56px Poppins;
    letter-spacing: 4.8px;
    color: #dedede;
    text-transform: uppercase;

    @include mobile {
      font-size: 24px;
      line-height: 28px;
    }
  }

  p {
    max-width: 700px;
    margin: 0 auto;
  }
}


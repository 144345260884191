$medium-desktop: 1360px;
$small-desktop: 1200px;
$tablet: 1024px;
$mobile: 767px;
$maxContainWidth: 1600px;

@mixin medium-desktop {
  @media (max-width: $medium-desktop) {
    @content;
  }
}

@mixin small-desktop {
  @media (max-width: $small-desktop) {
    @content;
  }
}

@mixin tablet {
  @media (max-width: $tablet) {
    @content;
  }
}

@mixin mobile {
  @media (max-width: $mobile) {
    @content;
  }
}

@mixin mobile-min {
  @media (min-width: ($mobile + 1)) {
    @content;
  }
}

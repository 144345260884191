@import '../../styles/base-refs';

.mainCopyAnchor {
  position: absolute;
  top: -60px;
  left: 0;
  width: 100%;
}

.mainCopy {
  position: relative;
  padding: 70px 24px 60px;
  text-align: center;

  h3 {
    margin-bottom: 8px;
    font: normal normal normal 34px/43px Lora;
    letter-spacing: 0px;
    color: #dedede;

    @include mobile {
      font-size: 24px;
      line-height: 28px;
    }
  }

  p {
    margin-bottom: 12px;

    &:last-of-type {
      margin-bottom: 0;
    }

    &.subheader {
      margin-top: 48px;
      font-weight: bold;
    }
  }

  .startSelection {
    margin-top: 64px;
  }
}

.optionRow {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 54px;
  margin-top: 36px;

  @include tablet {
    gap: 12px;
  }
}

.option {
  width: 200px;
  cursor: pointer;

  @include mobile {
    width: 33.3333333%;
  }

  > p {
    margin-top: 12px;
    text-align: center;
    font-size: 24px;
    line-height: 35px;
    letter-spacing: 3.6px;
    color: #7c7c7c;
    text-transform: uppercase;

    @include tablet {
      font-size: 18px;
    }

    @include mobile {
      font-size: 11px;
      line-height: 20px;
    }
  }

  &.active {
    > p {
      font-weight: 500;
      color: #dedede;
    }

    img {
      filter: grayscale(0);
    }
  }

  .imgContainer {
    height: 100px;

    @include mobile {
      height: 94px;
    }

    &.eth {
      img {
        width: 78px;

        @include mobile {
          width: 68px;
        }
      }
    }
  }

  img {
    width: 100px;
    filter: grayscale(1);

    @include mobile {
      width: 80px;
    }
  }
}

.sansHeader {
  font-family: Poppins;
  font-size: 32px;
  font-weight: 600;
  letter-spacing: 4.8px;
  line-height: 48px;
  color: #dedede;
  text-shadow: 0px 3px 20px #000000f0;
  text-transform: uppercase;

  @include mobile {
    font-size: 24px;
    line-height: 28px;
  }
}

.tutorialSection {
  padding-top: 70px;
  border-radius: 60px;

  .text {
    max-width: 800px;
    margin: 0 auto;
    font-family: Poppins;
    font-size: 18px;
    line-height: 32px;
    letter-spacing: 0px;
    color: #dedede;
    text-shadow: 0px 3px 20px #000000f0;

    @include tablet {
      font-size: 16px;
    }

    @include mobile {
      font-size: 14px;
      line-height: 24px;
    }
  }

  a {
    color: #91d565;
  }
}

.creditSection {
  border-top: 3px solid #383120;
  text-align: center;
}

.avalancheSection {
  border-top: 3px solid #361919;
  border-bottom: 3px solid #361919;
}

.ethereumSection {
  border-top: 3px solid #1f1f38;
  border-bottom: 3px solid #1f1f38;
}

.stepOne {
  text-align: center;
  margin-bottom: 48px;
  padding: 0 24px;
}

.logoRow {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 58px;
  margin: 36px 0;
  flex-wrap: wrap;

  img {
    width: 200px;
  }

  @include mobile {
    gap: 36px;
  }
}

.onrampBanner {
  position: relative;
  width: 100%;
  height: 485px;
  margin-top: 100px;
  margin-bottom: 30px;
  background: url('./images/onramp-banner.png') center center / cover no-repeat;
}

.modalImage {
  position: absolute;
  top: 50%;
  left: 0;
  width: 540px;
  transform: translateY(-50%);
}

.buttonWrapper {
  margin-top: 24px;
  display: flex;
  justify-content: center;
}

.buttonWrapperLeft {
  margin-top: 24px;
}

.playButton {
  padding: 12px 32px;
  background: transparent linear-gradient(180deg, #6db75c 0%, #4b8a4d 100%) 0% 0% no-repeat
    padding-box;
  box-shadow: inset 0px 3px 6px #00000029, 2px 0px 10px #ebfea965;
  border: 2px solid #91d565;
  border-radius: 10px;
  text-align: left;
  font: normal normal 500 16px/25px Poppins;
  letter-spacing: 1.28px;
  color: #dedede !important;
  text-shadow: 0px 1px 6px #325d339f;
  text-transform: uppercase;
  opacity: 0.9;
  transition: 0.2s opacity ease-in-out;

  @include mobile {
    font-size: 12px;
  }

  &:hover {
    opacity: 1;
  }
}

h3.sectionHeader {
  margin-bottom: 12px;
  font: normal normal 500 32px/48px Poppins;
  letter-spacing: 4.8px;
  color: #dedede;
  text-transform: uppercase;

  @include mobile {
    font-size: 24px;
    line-height: 28px;
  }
}

.rightSection,
.leftSection {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 32px 48px;
  max-width: 1800px;
  margin: 0 auto;
  gap: 32px;

  @include mobile {
    flex-direction: column;
    text-align: center;
  }

  p {
    margin-bottom: 12px;
    line-height: 32px;

    @include mobile {
      line-height: 22px;
    }

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  .leftColumn,
  .rightColumn {
    width: 50%;

    @include mobile {
      width: 100%;
    }
  }
}

.ethImage {
  max-width: 75%;
}

.traderImage {
  max-width: 80%;
}

.textSection {
  padding: 0 24px;
}

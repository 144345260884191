@import '../styles/base-refs';

.footer {
  padding: 28px 60px 0;

  @include tablet {
    padding: 28px 32px 0;
  }

  .footerTopRow {
    display: flex;
    justify-content: space-between;

    @include mobile {
      flex-direction: column;
    }

    .left {
      display: flex;
      align-items: center;
      gap: 32px;

      @include tablet {
        gap: 16px;
      }

      @include mobile {
        flex-direction: column;
      }

      .linkList {
        display: flex;
        gap: 48px;

        @include tablet {
          gap: 16px;
        }

        @include mobile {
          align-items: center;
          justify-content: center;
          flex-direction: column;
          gap: 6px;
        }
      }

      a {
        font: normal normal 500 14px/21px Poppins;
        letter-spacing: 1.12px;
        color: #7e7e7e;
        text-transform: uppercase;
        transition: 0.2s color ease-in-out;

        @include tablet {
          font-size: 12px;
        }

        &:hover {
          color: #fff;
        }
      }
    }

    .right {
      display: flex;
      align-items: center;
    }

    img {
      width: 40px;
    }
  }

  .social {
    display: flex;
    align-items: center;
    gap: 16px;
    margin: 0;
    padding: 0;
    margin-right: 24px;
    list-style: none;

    @include small-desktop {
      gap: 8px;
    }

    @include mobile {
      width: 100%;
      margin-top: 24px;
      margin-right: 0;
      justify-content: center;
    }

    a {
      color: #7e7e7e;
      transition: 0.2s color ease-in-out;

      &:before {
        font-size: 20px;
      }

      &:hover {
        color: #fff;
      }
    }
  }

  .footerBottomRow {
    padding: 20px 0;

    p {
      text-align: center;
      font: normal normal normal 14px/21px Poppins;
      letter-spacing: 0px;
      color: #3b3b3b;
    }
  }
}

@import '../../styles/base-refs';

.mainCopy {
  padding: 70px 24px 30px;
  text-align: center;

  .copy {
    max-width: 800px;
    margin: 0 auto;
  }

  h3 {
    margin-bottom: 8px;
    font: normal normal normal 34px/43px Lora;
    letter-spacing: 0px;
    color: #dedede;

    @include mobile {
      font-size: 24px;
      line-height: 28px;
    }
  }

  p {
    margin-bottom: 12px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }
}

* {
  box-sizing: border-box;
}

html,
body {
  overflow-x: hidden;
}

body {
  background: #0b0b0b;
  color: #ffffff;
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
  padding: 0px;
  margin: 0px;
  line-height: 180%;
  font-weight: 400;
  position: relative;
}

h1,
h2,
h3,
h4 {
  position: relative;
  font-family: 'Lora', serif;
  color: #dedede;
  font-weight: 500;
  line-height: initial;
  margin: 0px;
}

h1 {
  margin-top: 10px;
  font-size: 36px;
}

h2 {
  margin-bottom: 16px;
  font-size: 34px;
}

h3 {
  font-size: 26px;
}

.list-unstyled {
  padding: 0px;
  margin: 0px;
  list-style: none;
}

.kingdom-heading span {
  position: relative;
  z-index: 1;
}

.kingdom-heading:before {
  content: 'KINGDOMS';
  font-size: 90px;
  font-weight: bold;
  letter-spacing: 6px;
  opacity: 0.5;
  position: absolute;
  top: -100%;
  left: 50%;
  transform: translateX(-50%);
  color: #1e1d32;
  z-index: 0;
}
img {
  max-width: 100%;
}
.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}
a {
  color: #fbe375;
  text-decoration: none;
}
a:hover,
button:hover {
  color: #63f043;
}

/****
BUTTONS
****/
.btn {
  display: inline-block;
  padding: 10px 20px;
  border-radius: 15px;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 1px;
  border: 0px;
  min-width: 100px;
  text-align: center;
  position: relative;
  top: 0px;
  transition: all 0.2s ease-in-out;
}
.btn:hover {
  top: -5px;
}
.btn-primary {
  color: #100f21;
  background-size: 200% auto;
  background-image: linear-gradient(to right, #63f043 0%, #14c25a 51%, #63f043 100%);
  background-position: left center;
  /* background: linear-gradient(90deg, rgba(99,240,67,1) 0%, rgba(20,194,90,1) 100%); */
}
.btn-primary:hover {
  color: #100f21;
  background-position: right center;
}
.btn-dark {
  background: #100f21;
  color: #fbe375;
}
.btn-lg {
  font-size: 20px;
  padding: 16px 40px;
}

/****
BACKGROUNDS
****/
.highlighted-area {
  background: rgb(99, 240, 67);
  background: linear-gradient(90deg, rgba(99, 240, 67, 1) 0%, rgba(20, 194, 90, 1) 100%);
  color: #100f21;
}
.secondary-bg {
  background: #1e1d32;
}

.green-highlight {
  color: #63f043;
}
.padding {
  padding: 150px 60px;
}
.padding-sm {
  padding: 40px 0px;
}
.mt {
  margin-top: 40px;
}
.mb {
  margin-bottom: 40px;
}
.pb {
  padding-bottom: 40px;
}
.no-pb {
  padding-bottom: 0px;
}
.pt {
  padding-top: 40px;
}
.width-sm {
  max-width: 650px;
}
.width-md {
  max-width: 1000px;
}
.width-lg {
  max-width: 1400px;
}
.content {
  margin: 0 auto;
}
.content input:last-of-type {
  margin-left: 6px;
}
.show {
  visibility: visible;
}

/****
FLEXBOX
****/
.row {
  display: flex;
  flex-direction: row;
}
.vertical-center {
  align-items: center;
}
.horizontal-center {
  justify-content: center;
}
.col {
  flex: 1;
}

/****
ANIMATIONS
****/
@keyframes floating {
  from {
    top: 0;
  }
  50% {
    top: 30px;
  }
  to {
    top: 0px;
  }
}
@keyframes one-swap {
  from {
    opacity: 1;
    left: 50%;
    transform: scale(1) translate(-50%, -50%);
  }
  49% {
    opacity: 0;
    left: 15%;
    transform: scale(0.7) translate(-50%, -50%);
  }
  50% {
    opacity: 0;
    left: 70%;
    transform: scale(0.7) translate(-50%, -50%);
  }
  90% {
    opacity: 1;
    left: 50%;
    transform: scale(1) translate(-50%, -50%);
  }
}
@keyframes gem-swap {
  from {
    opacity: 0;
    left: 70%;
    transform: scale(0.7) translate(-50%, -50%);
  }
  50%,
  60% {
    opacity: 1;
    left: 50%;
    transform: scale(1) translate(-50%, -50%);
  }
  to {
    opacity: 0;
    left: 15%;
    transform: scale(0.7) translate(-50%, -50%);
  }
}
.float {
  animation: floating 3.5s ease-in-out;
  animation-iteration-count: infinite;
}

/****
SOCIAL
****/
.social a {
  color: #fbe375;
  text-align: left;
  font-size: 24px;
  margin-right: 16px;
}
.social a:hover {
  text-decoration: none;
  color: #63f043;
}
.social li {
  display: inline-block;
}
.website::before {
  font: var(--fa-font-solid);
  content: '\f0ac';
}

.org-chart {
  width: 90%;
  max-width: 1200px;
  padding-top: 64px;
  margin: 0 auto;
}

.org-chart h3 {
  margin-bottom: 24px;
  color: #fff;
}

/****
KINGDOM DETAILS
****/
.kingdom-details .col {
  border: 1px solid #fbe375;
  padding: 30px;
  margin: 0 20px;
}
.kingdom-details h3 {
  text-align: center;
  margin-top: -46px;
}
.kingdom-details h3 span {
  background: #100f21;
  padding: 0px 20px;
}
.kingdom-details .detail-img {
  width: 100%;
  margin-top: 20px;
  position: relative;
}
.kingdom-details .swap-one,
.kingdom-details .swap-gem {
  top: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
}
.kingdom-details .swap-one {
  opacity: 1;
  left: 50%;
  animation: one-swap 3s ease-in-out;
  animation-iteration-count: infinite;
}
.kingdom-details .swap-gem {
  opacity: 0;
  left: 75%;
  animation: gem-swap 3s ease-in-out;
  animation-iteration-count: infinite;
}
.kingdom-details .swap-jewel {
  opacity: 1;
  left: 50%;
  transform: scale(1) translate(-50%, -50%);
}

/****
WHITEPAPERS
****/
.scroll {
  position: relative;
  top: 0px;
}

/****
PARTNERS
****/
.partners a {
  display: inline-block;
  max-width: 240px;
}
.partners a img {
  width: 100%;
  min-width: 240px;
  transform: scale(1);
  transition: all 0.2s ease-in-out;
  transform-origin: center;
}
.partners a:hover img {
  transform: scale(1.1);
}
.partners-flex {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 32px;
}

/****
EXTRA
****/
.start-playing h2,
.start-playing .social a {
  color: #100f21;
}

/****
FOOTER
****/
footer {
  position: relative;
}
footer .social {
  color: #fbe375;
}
footer .social a,
.start-playing .social a {
  font-size: 34px;
  margin-left: 16px;
}
#mc_embed_signup input {
  background: white;
  padding: 16px 20px;
  border: 0px;
  border-radius: 15px;
  font-size: 14px;
}
#mc_embed_signup input[type='submit'] {
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: bold;
  color: #100f21;
  background: rgb(99, 240, 67);
  background: linear-gradient(90deg, rgba(99, 240, 67, 1) 0%, rgba(20, 194, 90, 1) 100%);
}

/* jQuery Countdown styles 2.0.0. */
.countdown-rtl {
  direction: rtl;
}
.countdown-row {
  clear: both;
  width: 100%;
  padding: 0px 2px;
  text-align: center;
}
.countdown-section {
  display: block;
  float: left;
  text-align: center;
  margin: 0 10px;
}
.countdown-amount {
  font-size: 50px !important;
  color: #63f043 !important;
}
.countdown-period {
  display: block;
}
.countdown-descr {
  display: block;
  width: 100%;
}
#countdown {
  margin: 0 auto;
}

/****
MEDIA QUERIES
****/
@media (max-width: 1200px) {
  #play,
  header .social {
    display: none;
  }
  .kingdom-details .row {
    flex-wrap: wrap;
  }
  .kingdom-details .row .col {
    min-width: 40%;
    margin-bottom: 40px;
  }
}

@media (max-width: 992px) {
  .padding {
    padding: 80px 40px;
  }
  .no-pb {
    padding-bottom: 0px;
  }
  h1 {
    font-size: 30px;
    margin-top: 0;
  }
  h2 {
    font-size: 30px;
  }
  h3 {
    font-size: 22px;
  }
  #logo {
    display: none;
  }
  #info {
    display: none;
  }
  .toggle-menu {
    background: transparent;
    border: 0px;
    color: #fbe375;
    width: 24px;
  }
  .mobile-nav {
    display: block;
    position: absolute;
    top: 18px;
    right: 20px;
  }
  .mobile-only {
    display: block;
  }
  .mobile-only.icon {
    display: inline-block;
    font-size: 24px;
  }
  .mobile-logo {
    display: block;
    max-width: 250px;
    /* margin-left: -20px; */
    margin-bottom: -10px;
    margin-left: auto;
    margin-right: auto;
  }
  nav {
    position: absolute;
    top: 60px !important;
    right: 0;
    background: #100f21;
    width: 100%;
  }
  nav ul {
    display: block !important;
  }
  nav li,
  nav li a {
    display: block;
  }
  nav li a,
  nav .navDropDown .navSubMenu li a {
    padding: 5px 0px;
  }
  nav .navDropDown .navSubMenu {
    visibility: visible !important;
    pointer-events: all;
    max-height: 1000px;
    position: relative;
    top: 0;
    left: 0;
    opacity: 1;
    text-align: center;
    font-size: 16px;
    background: transparent;
    border: 0;
    padding: 0px;
    transform: none;
  }
  .navDropDownLink {
    display: none;
  }
  .dropdown-content {
    visibility: hidden;
    transition: all 0.2s ease;
    opacity: 0;
    pointer-events: none;
  }
  .show {
    visibility: visible;
    opacity: 1;
    pointer-events: all;
  }
}

@media (max-width: 786px) {
  h1 {
    font-size: 24px;
  }
  .kingdom-details .row {
    flex-direction: column;
  }
  .kingdom-details .row .col {
    max-width: 300px;
    margin: 0 auto 40px;
  }
  .sm-1 {
    order: 1;
  }
  .sm-2 {
    order: 2;
  }
  .kingdom-heading:before {
    content: '';
  }
  .hero h3 {
    display: none;
  }
  #mc_embed_signup input {
    width: 100%;
    margin-bottom: 10px;
  }
  .content input:last-of-type {
    margin-left: 0;
  }
}

@media (max-width: 600px) {
  .contact iframe {
    height: 600px !important;
  }
  .popupVidContainer iframe {
    width: 280px;
    height: 157.5px;
  }
}

.team {
  line-height: 130%;
}
.team h3 {
  text-align: center;
  text-transform: none;
  margin-bottom: 10px;
  letter-spacing: 0px;
  font-size: 20px;
}
.team .member-info {
  text-align: center;
  margin-top: 2px;
  margin-bottom: 2px;
  font-size: 14px;
}
.team .detail-img {
  width: 200px;
  height: 202px;
  margin: 0 auto;
  position: relative;
}
.team .detail-img img {
  width: 188px;
}
.team .detail-img:before {
  content: '';
  display: block;
  position: absolute;
  top: -5px;
  left: 50%;
  transform: translateX(-50%);
  width: 200px;
  height: 202px;
  background-image: url('./img/team/profileBox01.png');
  background-size: 100%;
  image-rendering: -moz-crisp-edges;
  image-rendering: pixelated;
  z-index: 2;
}
.team .row {
  flex-wrap: wrap;
  justify-content: center;
}
.team .row .col {
  min-width: 225px;
  margin-top: 30px;
  flex: none;
}
.team .team-socials {
  padding: 0px;
  margin: 0px;
  list-style: none;
  text-align: center;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.team .team-socials li {
  padding: 0px 6px;
  position: relative;
}
.secondary-bg {
  padding-top: 260px;
  padding-bottom: 260px;
}
.secondary-bg .kingdom-heading:before {
  color: #100f21;
}
.secondary-bg:after {
  content: '';
  clip-path: polygon(50% 0, 100% 100%, 0 100%);
  display: block;
  background: #100f21;
  height: 180px;
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  right: 0;
}
.team .team-socials li .tooltip {
  position: absolute;
  visibility: hidden;
  opacity: 0;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  background: #100f21;
  text-align: center;
  font-size: 12px;
  border: 1px solid rgba(255, 255, 255, 0.3);
  padding: 2px 10px;
  border-radius: 4px;
  transition: all 0.2s ease-in-out;
}
.team .team-socials li:hover .tooltip {
  opacity: 1;
  visibility: visible;
}
.team small {
  opacity: 0.6;
}
.team .detail-img .hiddenPFP {
  visibility: hidden;
  opacity: 0;
  transition: all 0.2s ease-in-out;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
}
.team .detail-img:hover .hiddenPFP {
  visibility: visible;
  opacity: 1;
}

@import '../../../styles/base-refs';

.getStarted {
  margin-top: 100px;
  margin-bottom: 60px;
  padding: 0 24px;

  @include mobile {
    margin-top: 48px;
  }

  h3 {
    @include header;
  }

  p {
    a {
      color: white;
      text-decoration: underline;
    }
  }

  .getStartedContainer {
    max-width: $maxContainWidth;
    margin: 0 auto;
  }

  .gameplayRow {
    display: flex;
    align-items: stretch;
    justify-content: center;
    gap: 20px;

    img {
      min-height: 120px;
      max-height: 120px;
    }

    @include mobile {
      flex-direction: column;
    }
  }

  .gameplayBlock {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    width: 33.3333333%;
    padding: 80px 32px 32px;
    text-align: center;

    @include tablet {
      padding: 80px 24px 32px;
    }

    @include mobile {
      width: 100%;
      padding: 36px 24px 32px;
    }

    h3 {
      font-size: 34px;
      letter-spacing: 0px;
    }

    h4 {
      font-family: $sans-serif-font;
      font-size: 20px;
      font-weight: medium;
      letter-spacing: 1.6px;
      color: #dedede;
      text-transform: uppercase;

      @include tablet {
        font-size: 18px;
      }
    }

    p {
      font-size: 16px;
      letter-spacing: 0px;
      color: #dedede;

      @include tablet {
        font-size: 14px;
      }
    }

    .blockGradient {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: transparent linear-gradient(180deg, #000000 0%, #5c5c5c 49%, #5c5c5c 100%) 0% 0%
        no-repeat padding-box;
      opacity: 0.2;
      pointer-events: none;
    }
  }

  .buttonWrapper {
    margin-top: 24px;
    display: flex;
    justify-content: center;
  }

  .or {
    font-weight: bold;
    font-size: 24px;
    margin: 36px 0 8px;
    text-align: center;
  }

  .hyperplay {
    display: flex;
    justify-content: center;
    margin-top: 12px;
  }

  .playButton {
    padding: 12px 32px;
    background: transparent linear-gradient(180deg, #6db75c 0%, #4b8a4d 100%) 0% 0% no-repeat
      padding-box;
    box-shadow: inset 0px 3px 6px #00000029, 2px 0px 10px #ebfea965;
    border: 2px solid #91d565;
    border-radius: 10px;
    text-align: left;
    font: normal normal 500 16px/25px Poppins;
    letter-spacing: 1.28px;
    color: #dedede;
    text-shadow: 0px 1px 6px #325d339f;
    text-transform: uppercase;
    opacity: 0.9;
    transition: 0.2s opacity ease-in-out;

    &:hover {
      opacity: 1;
    }
  }
}

@import '../../../styles/base-refs';

.gamefiDetails {
  position: relative;
  height: 400px;
  max-width: 2000px;
  padding: 0 24px 0 0;
  margin: 24px auto;

  @include mobile {
    padding: 24px;
  }

  h3 {
    margin-bottom: 6px;
    @include header;
  }

  h4 {
    margin-bottom: 6px;
    @include subheader;
  }

  p {
    @include bodytext;
  }

  .gamefiDetailsRow {
    display: flex;
    gap: 24px;
    height: 100%;
  }

  .columnLeft {
    width: 1000px;
    background: url('../img/marketplace.png') center center / cover no-repeat;

    @include mobile {
      display: none;
    }
  }

  .columnRight {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1;
    max-width: 1000px;
    min-width: 360px;

    @include mobile {
      min-width: 0;
    }

    .copy {
      max-width: 420px;
      margin: 0 auto;
      text-align: center;
    }
  }

  .imageWrapper {
    position: relative;
    width: 340px;
  }

  .tradeScreenWrapper {
    position: relative;
    margin: -54px 0 0 96px;

    img {
      width: 100%;

      &.trader {
        position: absolute;
        bottom: 28px;
        left: -30px;
        width: 102px;
      }

      &.druid {
        position: absolute;
        bottom: 15px;
        right: -35px;
        width: 126px;
      }
    }
  }
}

@import '../styles/base-refs';

.headerPlaceholder {
  height: 80px;
}

.header {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 80px;
  padding: 0 24px;
  background-color: $off-black;
  z-index: 10;

  .logo {
    width: 306px;
    line-height: 0;

    @include small-desktop {
      width: 220px;
    }
  }

  .headerCenter {
    display: flex;
    align-items: center;
    justify-content: center;
    list-style: none;
    padding: 0;
    margin: 0;
    gap: 24px;

    @include small-desktop {
      gap: 12px;
    }

    li {
      padding: 6px 0;

      a,
      button {
        padding: 0;
        background-color: transparent;
        border: 0;
        font-family: $sans-serif-font;
        font-size: 16px;
        font-weight: 500;
        letter-spacing: 1.28px;
        color: #dedede;
        cursor: pointer;
        text-transform: uppercase;
        transition: 0.2s color ease-in-out;

        &:hover {
          color: #fff;
        }
      }
    }
  }

  .dropdownContainer {
    position: relative;

    &:hover {
      .dropdownLinks {
        visibility: visible;
        opacity: 1;
        pointer-events: all;
      }
    }

    .dropdownLinks {
      position: absolute;
      top: 100%;
      left: 50%;
      padding: 0;
      margin: 0;
      background-color: #0b0b0b;
      list-style: none;
      transform: translateX(-50%);
      visibility: hidden;
      opacity: 0;
      pointer-events: none;
      transition: 0.2s all ease-in-out;

      li {
        padding: 6px 18px;
      }
    }
  }

  a.buyJewel {
    font-weight: bold !important;
    color: #91d565 !important;
  }

  .headerRight {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
  }

  .social {
    display: flex;
    align-items: center;
    gap: 16px;
    margin: 0;
    padding: 0;
    margin-right: 24px;
    list-style: none;

    @include small-desktop {
      gap: 8px;
    }

    @include tablet {
      display: none;
    }

    a {
      color: #7e7e7e;
      transition: 0.2s color ease-in-out;

      &:before {
        font-size: 20px;
      }

      &:hover {
        color: #fff;
      }
    }
  }

  .playButton {
    padding: 12px 32px;
    background: transparent linear-gradient(180deg, #6db75c 0%, #4b8a4d 100%) 0% 0% no-repeat
      padding-box;
    box-shadow: inset 0px 3px 6px #00000029, 2px 0px 10px #ebfea965;
    border: 2px solid #91d565;
    border-radius: 10px;
    text-align: left;
    font: normal normal 500 16px/25px Poppins;
    letter-spacing: 1.28px;
    color: #dedede;
    text-shadow: 0px 1px 6px #325d339f;
    text-transform: uppercase;
    opacity: 0.9;
    transition: 0.2s opacity ease-in-out;

    @include mobile {
      display: none;
    }

    &:hover {
      opacity: 1;
    }
  }

  .mobileNav {
    line-height: 0;
    cursor: pointer;
  }

  .hideOnDesktop {
    display: none;

    @include tablet {
      display: block;
    }
  }

  .dropdownContent {
    @include tablet {
      position: absolute;
      top: 80px !important;
      left: 0;
      width: 100%;
      padding-bottom: 12px;
      background-color: #0b0b0b;
      text-align: center;
      border-bottom: 1px solid rgba(255, 255, 255, 0.3);
      border-top: 1px solid rgba(255, 255, 255, 0.3);
      visibility: hidden;
      transition: all 0.2s ease;
      opacity: 0;
      pointer-events: none;
    }

    &.show {
      visibility: visible;
      opacity: 1;
      pointer-events: all;
    }
  }
}

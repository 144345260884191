@import '../../../styles/base-refs';

.collaborate {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 450px;
  margin-top: 64px;
  gap: 16px;
  padding: 0 24px;
  background: transparent url('../img/contact/contact-banner.png') 0% 0% no-repeat padding-box;

  h3 {
    @include header;
  }

  p {
    max-width: 600px;
    margin: 0 auto;
    @include bodytext;
  }
}

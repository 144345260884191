@import '../../../styles/base-refs';

.hero {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 42px 24px 64px;
  min-height: 600px;
  background: transparent url('../img/serendale-bg.png') center center / cover no-repeat;
  mix-blend-mode: normal;

  .heroScroll {
    position: absolute;
    top: 0;
  }

  h2 {
    max-width: 940px;
    margin: 0 auto;
    text-align: center;
    font-family: $sans-serif-font;
    font-size: 32px;
    font-weight: 500;
    letter-spacing: 4.8px;
    text-transform: uppercase;
    color: #dedede;
    opacity: 1;

    @include mobile {
      font-size: 20px;
      letter-spacing: 3px;
    }
  }

  .heroContent {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 32px;
  }

  .logo {
    width: 100%;
    max-width: 640px;
  }

  .buttonWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 32px;

    @include mobile {
      flex-direction: column;
      gap: 12px;
    }
  }

  .learnButton,
  .playButton {
    padding: 24px 0;
    border: 2px solid #91d565;
    border-radius: 10px;
    font-family: $sans-serif-font;
    font-size: 24px;
    font-weight: 500;
    letter-spacing: 0px;
    min-width: 240px;
    text-align: center;
    line-height: 1;
    cursor: pointer;
    color: #dedede;
    opacity: 0.9;
    transition: 0.2s opacity ease-in-out;

    &:hover {
      opacity: 1;
    }
  }

  .learnButton {
    color: #91d565;
    background: #00000096 0% 0% no-repeat padding-box;
  }

  .playButton {
    background: transparent linear-gradient(180deg, #6db75c 0%, #4b8a4d 100%) 0% 0% no-repeat
      padding-box;
    box-shadow: inset 0px 3px 6px #00000029, 2px 0px 10px #ebfea965;
  }

  .or {
    font-weight: bold;
    font-size: 24px;
    margin: 0;
    text-align: center;
  }

  .hyperplay {
    display: flex;
    justify-content: center;
    margin-top: -28px;
  }
}

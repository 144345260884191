@import '../../styles/base-refs';

.rightSection {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 32px 48px;
  max-width: 1800px;
  margin: 0 auto;
  gap: 32px;

  @include mobile {
    flex-direction: column;
    text-align: center;
  }

  p {
    margin-bottom: 12px;
    line-height: 32px;

    @include mobile {
      line-height: 22px;
    }

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  .leftColumn,
  .rightColumn {
    width: 50%;

    @include mobile {
      width: 100%;
    }
  }
}

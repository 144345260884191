@import '../../../styles/base-refs';

.partners {
  padding: 64px;
  text-align: center;

  h2 {
    margin-bottom: 24px;
  }

  p {
    max-width: 800px;
    margin: 0 auto 32px;
    font: normal normal normal 18px/27px Poppins;
    letter-spacing: 0px;
    color: #dedede;
  }

  .partnerWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 90px;
    max-width: 1340px;
    margin: 0 auto;

    a {
      margin-bottom: -42px;
    }
  }
}

@import '../../styles/base-refs';

$circleHeight: 50px;

.returnToTopArrow {
  position: fixed;
  bottom: 16px;
  right: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: black;
  color: #dedede;
  border: 2px solid #dedede;
  padding: 0;
  width: $circleHeight;
  height: $circleHeight;
  min-height: $circleHeight;
  min-width: $circleHeight;
  border-radius: 50%;
  cursor: pointer;
  opacity: 0;
  pointer-events: none;
  transition: 0.3s all ease-in-out;
  z-index: 10;

  &.active {
    opacity: 1;
    pointer-events: all;
  }

  span {
    padding-bottom: 2px;
    font-size: 20px;
    line-height: 0;
    color: #dedede;

    &:hover {
      color: #dedede;
    }
  }
}
